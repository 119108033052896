import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['validationStatus', 'presentation'];

  static outlets = [
    'containers--product-configurations--accordion-component--accordion',
  ];

  static values = {
    sectionName: String,
    choicePlaceholderHtml: String,
  };

  onSubsectionOptionValidation(event) {
    this.validSelection = event.detail.isValid;
    this.accordionOutlet.setSelected(this.validSelection);
    this.fireSelectionMadeEvent();
  }

  fireSelectionMadeEvent() {
    this.fireEvent('selectionMade', {
      accordionOutlet: this.accordionOutlet,
    });
  }

  get accordionOutlet() {
    return this
      .containersProductConfigurationsAccordionComponentAccordionOutlet;
  }
}
