import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = [
    'tab',
    'tabHighlight',
    'content',
    'contentContainer',
    'navigationContainer',
  ];

  static classes = [
    'selectedTab',
    'slidingContentContainer',
    'slidingOutLeft',
    'slidingOutRight',
    'slidingInLeft',
    'slidingInRight',
    'hidden',
  ];

  static values = {
    currentSlug: String,
    appearance: String,
    isSticky: Boolean,
    animationDuration: Number,
    shouldScrollToContent: Boolean,
  };

  connect() {
    this.tabTargets.forEach((tab) =>
      tab.parentElement.classList.remove('relative'),
    );
  }

  scrollToContent() {
    if (this.hasTabQueryParam() && this.shouldScrollToContentValue) {
      this.element.scrollIntoView();
    }
  }

  selectTab(e) {
    e.preventDefault();

    const { currentTarget } = e;
    const targetSlug = currentTarget.dataset.slug;

    if (targetSlug !== this.currentSlugValue) {
      this.updateURL(currentTarget);
      this.tabTargets.forEach((tab) =>
        tab.classList.remove(...this.selectedTabClasses),
      );

      this.highlightSelectedTab(currentTarget);

      this.animateTabContent(currentTarget);

      this.currentSlugValue = targetSlug;
    }
  }

  highlightSelectedTab(target) {
    switch (this.appearanceValue) {
      case 'primary':
        this.animateTabHighlight(target);
        break;
      case 'transparent':
        target.classList.add(...this.selectedTabClasses);
        break;
      default:
        break;
    }
  }

  async animateTabContent(target) {
    const targetSlug = target.dataset.slug;
    const targetContent = this.contentTargets.find(
      (tab) => tab.dataset.slug === targetSlug,
    );

    this.contentContainerTarget.classList.add(
      this.slidingContentContainerClass,
    );

    if (
      this.getTabIndex(this.currentSlugValue) > this.getTabIndex(targetSlug)
    ) {
      await this.previousTab(this.currentContent, targetContent);
    } else {
      await this.nextTab(this.currentContent, targetContent);
    }

    if (this.isStickyValue) {
      this.element.scrollIntoView({
        behavior: 'smooth',
      });
    }

    await this.waitForAnimations();

    this.contentContainerTarget.classList.remove(
      this.slidingContentContainerClass,
    );
  }

  async previousTab(current, target) {
    if (this.slideTabTimeout) {
      this.hideAllTargets();
      clearTimeout(this.slideTabTimeout);
    }

    target.classList.add(...this.slidingInLeftClasses);
    target.classList.remove(this.hiddenClass);
    current.classList.add(...this.slidingOutRightClasses);

    await this.waitForAnimations();

    current.classList.remove(...this.slidingOutRightClasses);
    current.classList.add(this.hiddenClass);

    target.classList.remove(...this.slidingInLeftClasses);
  }

  async nextTab(current, target) {
    if (this.slideTabTimeout) {
      this.hideAllTargets();
      clearTimeout(this.slideTabTimeout);
    }

    target.classList.add(...this.slidingInRightClasses);
    target.classList.remove(this.hiddenClass);
    current.classList.add(...this.slidingOutLeftClasses);

    await this.waitForAnimations();

    current.classList.remove(...this.slidingOutLeftClasses);
    current.classList.add(this.hiddenClass);

    target.classList.remove(...this.slidingInRightClasses);
  }

  animateTabHighlight(target) {
    if (!this.hasTabHighlightTarget) {
      throw new Error('Missing tab highlight target!');
    }

    const targetLeft = target.offsetLeft;
    const currentLeft = this.tabHighlightTarget.offsetLeft;

    this.tabHighlightTarget.style.transition = `${this.animationDurationValue}ms ease-in-out`;
    this.tabHighlightTarget.style.translate = `${Math.floor(
      targetLeft - currentLeft,
    )}px`;
    this.tabHighlightTarget.style.width = `${target.offsetWidth}px`;

    if (this.animateTabTimeout) {
      clearTimeout(this.animateTabTimeout);
    }

    this.animateTabTimeout = setTimeout(() => {
      target.classList.add(...this.selectedTabClasses);
      this.animateTabTimeout = null;
    }, 150);
  }

  getTabIndex(slug) {
    return this.tabTargets.findIndex((tab) => tab.dataset.slug === slug);
  }

  hideAllTargets() {
    this.contentTargets.forEach((target) => {
      target.classList.remove(
        ...this.slidingOutLeftClasses,
        ...this.slidingInLeftClasses,
        ...this.slidingInRightClasses,
        ...this.slidingOutRightClasses,
      );
      target.classList.add(this.hiddenClass);
    });
  }

  updateURL(target) {
    const linkEl = target.getElementsByTagName('a')[0];

    if (!linkEl) {
      return;
    }

    if (!linkEl.href) {
      throw new Error(
        `Missing href attribute on link element for ${target.dataset.slug} tab`,
      );
    }

    Turbo.navigator.history.replace(linkEl);
  }

  waitForAnimations() {
    return new Promise((resolve) => {
      this.slideTabTimeout = setTimeout(() => {
        this.slideTabTimeout = null;
        resolve();
      }, this.animationDurationValue);
    });
  }

  hasTabQueryParam() {
    const queryString = window.location.search;
    return new URLSearchParams(queryString).has('tab');
  }

  get currentContent() {
    return this.contentTargets.find(
      (tab) => tab.dataset.slug === this.currentSlugValue,
    );
  }
}
