import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['eye'];

  followCursor(event) {
    if (this.isLargeScreen()) {
      this.eyeTargets.forEach((target) => {
        const x = target.getBoundingClientRect().x + target.offsetWidth / 2;
        const y = target.getBoundingClientRect().y + target.offsetHeight / 2;
        const radian = Math.atan2(event.clientX - x, event.clientY - y);
        const rotation = radian * (180 / Math.PI) * -1 + 180;

        target.style.transform = `rotate(${rotation}deg)`;
      });
    }
  }

  isLargeScreen() {
    return window.matchMedia('(min-width: 1024px)').matches;
  }
}
