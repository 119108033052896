import Swiper from 'swiper';
import ApplicationController from '../../../../application_component/application_controller';

const tailwindConfig = preval`
      const resolveConfig = require('tailwindcss/resolveConfig');
      const tailwindConfig = require('../../../../../../tailwind.config');
      module.exports = resolveConfig(tailwindConfig);
    `;

export default class extends ApplicationController {
  static targets = ['container'];

  static values = { slidesPerView: Number };

  initialize() {
    const slidesPerView =
      this.slidesPerViewValue > 3 ? this.slidesPerViewValue : 3;
    this.fullConfig = tailwindConfig;
    this.breakpointLg = this.fullConfig.theme.screens.lg.replace('px', '');
    this.swiper = new Swiper(this.containerTarget, {
      init: false,
      spaceBetween: 25,
      slidesPerView: 'auto',
      centerInsufficientSlides: true,
      breakpoints: {
        [this.breakpointLg]: {
          allowTouchMove: false,
          preventClicks: false,
          slidesPerView,
        },
      },
      preloadImages: false,
    });
  }

  connect() {
    this.swiper?.init();
  }

  update() {
    this.swiper?.update();
  }

  disconnect() {
    this.swiper?.destroy();
    this.swiper = undefined;
  }
}
