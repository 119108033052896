import ApplicationController from '../../../application_component/application_controller';
import Analytics from '../../../../assets/javascript/application/analytics';

export default class extends ApplicationController {
  static targets = [
    'successMessage',
    'errorMessage',
    'emailInput',
    'form',
    'description',
  ];

  static values = { formName: String };

  showSuccessMessage(event) {
    event.preventDefault();
    this.trackSuccess();

    this.successMessageTarget.classList.remove('hidden');
    this.hideInput();
  }

  showErrorMessage(event) {
    const [data, , xhr] = event.detail;
    this.emailInputTarget.classList.add(
      'border-error',
      'hover:border-error',
      'focus:border-error',
    );
    if (xhr.status === 500) {
      this.errorMessageTarget.innerHTML =
        'Something went wrong, please try again';
    } else {
      this.errorMessageTarget.innerHTML = data.error;
    }
  }

  hideInput() {
    this.formTarget.classList.add('hidden');
    this.descriptionTargets.forEach((element) => {
      element.classList.add('hidden');
    });
  }

  trackSuccess() {
    Analytics.trackDeviceOnly(Analytics.newsletterSubscribedEvent, {
      form_name: this.formNameValue,
    });
  }
}
