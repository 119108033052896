import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static values = { imageUrl: String, sectionName: String };

  static outlets = [
    'containers--product-configurations--slider-component--slider',
  ];

  showVariantImage() {
    if (this.imageUrlValue) {
      this.sliderOutlet.showVariantImage(
        this.imageUrlValue,
        this.sectionNameValue,
      );
    }
  }

  get sliderOutlet() {
    return this.containersProductConfigurationsSliderComponentSliderOutlet;
  }
}
