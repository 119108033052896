import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static values = {
    currency: String,
    optionTypeId: Number,
    optionValueId: Number,
    prebuilt: Boolean,
    isProcessor: Boolean,
    available: Boolean,
    sectionName: String,
  };

  static targets = [
    'amount',
    'discountPercentage',
    'discountPercentageContainer',
    'optionValueInput',
    'outOfStockCaption',
    'unavailableForSystemCaption',
  ];

  static outlets = [
    'containers--product-configurations--option-value-component--option-value',
    'containers--product-configurations--subchoice-variant-component--subchoice-variant',
  ];

  updatePrice(
    currentVariant,
    relatedVariant,
    cheapestRelatedVariantForOptionType,
  ) {
    if (!relatedVariant?.isAvailable) {
      this.hidePrices();
      this.setDatasetAbsoluteDisplayAmount(null);
      return;
    }

    const amountToShow = this.getAmountToShow(
      currentVariant?.amount,
      relatedVariant?.amount,
    );

    const optionValueAmount = this.getAmountToShow(
      cheapestRelatedVariantForOptionType?.amount,
      currentVariant?.amount,
    );

    this.setDatasetAbsoluteDisplayAmount(optionValueAmount);
    this.setPrice(amountToShow);
    this.setDiscountPercentage(relatedVariant?.discountPercentage);
  }

  updateAvailability(optionValue) {
    if (this.isProcessorValue) return;

    const availability = optionValue.availability;
    const available = optionValue.available;

    this.availableValue = available; // This is read by the section controller
    this.element.setAttribute('data-orderability', availability);
    this.optionValueInputTarget.disabled = !available;

    this.outOfStockCaptionTarget.classList.toggle(
      'hidden',
      availability !== 'out_of_stock',
    );

    this.unavailableForSystemCaptionTarget.classList.toggle(
      'hidden',
      availability !== 'unavailable',
    );
  }

  setDatasetAbsoluteDisplayAmount(displayAmount) {
    if (this.isProcessorValue || displayAmount === 0) {
      this.element.dataset.absoluteDisplayAmount = '';
    } else {
      this.element.dataset.absoluteDisplayAmount = this.formatPrice(
        displayAmount,
        this.currencyValue,
        false,
      );
    }
  }

  setPrice(amount) {
    const formattedPrice = this.formatPrice(amount, this.currencyValue);

    this.amountTarget.innerHTML = formattedPrice;
    this.amountTarget.classList.toggle('hidden', !formattedPrice);
  }

  setDiscountPercentage(discountPercentage) {
    if (!this.isProcessorValue) return;

    this.discountPercentageContainerTarget.classList.toggle(
      'hidden',
      !discountPercentage,
    );
    this.discountPercentageTarget.innerHTML = discountPercentage;
  }

  hidePrices() {
    this.amountTarget.innerHTML = '';
    this.discountPercentageContainerTarget.classList.add('hidden');
  }

  getAmountToShow(currentAmount, relatedAmount) {
    if (Number.isNaN(currentAmount) || Number.isNaN(relatedAmount)) {
      return 0;
    }
    return relatedAmount - currentAmount;
  }

  selectInput() {
    this.toggleSubchoiceContainers();
  }

  toggleSubchoiceContainers() {
    this.optionValueOutlets.forEach((outlet) =>
      outlet.toggleSubchoiceContainer(),
    );
  }

  toggleSubchoiceContainer() {
    let selected = this.optionValueInputTarget.checked;
    this.element.classList.toggle('selected', selected);

    if (!selected) {
      this.subchoiceVariantOutlets.forEach((outlet) => outlet.deselectOption());
    }
  }

  disableInput() {
    this.optionValueInputTarget.disabled = true;
  }

  enableInput() {
    this.optionValueInputTarget.disabled = false;
  }

  reset() {
    this.enableInput();
    this.optionValueInputTarget.checked = false;
  }

  get optionValueId() {
    return (
      parseInt(this.optionValueInputTarget.value, 10) ||
      this.optionValueInputTarget.value
    );
  }

  get optionValueOutlets() {
    return this
      .containersProductConfigurationsOptionValueComponentOptionValueOutlets;
  }

  get subchoiceVariantOutlets() {
    return this
      .containersProductConfigurationsSubchoiceVariantComponentSubchoiceVariantOutlets;
  }
}
