export default class {
  constructor(variants, optionValuesMap) {
    this.variants = variants;
    this.optionValuesMap = optionValuesMap;
  }

  get availability() {
    if (this.availableVariants.length === 0) {
      return 'unavailable';
    }

    if (
      this.availabilityCounts['out_of_stock'] === this.availableVariants.length
    ) {
      return 'out_of_stock';
    }

    if (this.availabilityCounts['in_stock'] === this.availableVariants.length) {
      return 'in_stock';
    }

    return 'partially_in_stock';
  }

  get availabilityCounts() {
    return this.availableVariants.reduce((counts, variant) => {
      counts[variant.availability] = (counts[variant.availability] || 0) + 1;
      return counts;
    }, {});
  }

  get available() {
    const availability = this.availability;
    return availability === 'in_stock' || availability === 'partially_in_stock';
  }

  get availableVariants() {
    return this.variants.filter((variant) =>
      variant.hasOptionValuesMap(this.optionValuesMap),
    );
  }
}
