import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static values = { callbackFunction: String };

  connect() {
    window[this.callbackFunctionValue] = () => {
      this.element.closest('form').requestSubmit();
    };
  }
}
