import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['submitButton'];

  updateSubmitButtonTarget({ currentTarget }) {
    const formTarget = currentTarget.value;
    this.submitButtonTarget.setAttribute('form', formTarget);
  }

  showNewAddressForm() {
    this.fireEvent('showNewAddressForm');
  }
}
