import { loadStripe } from '@stripe/stripe-js/pure';
import ApplicationController from '../../../../../application_component/application_controller';
import Meta from '../../../../../../assets/javascript/application/meta';
import StripeError from './stripe_error';

export default class extends ApplicationController {
  static targets = ['element', 'errors', 'submitButton'];

  static values = {
    publishableKey: String,
    intentEndpoint: String,
    processPaymentEndpoint: String,
    billingDetails: Object,
    redirectUrl: String,
    cartUrl: String,
    orderNumber: String,
    errorMessage: String,
    walletPaymentSourceId: String,
    walletPaymentSourceType: String,
    advancedFraudDetection: Boolean,
  };

  initialize() {
    this.language = Meta.findByName('language');
    this.authToken = Meta.findByName('csrf-token');
    this.publishableKey = this.publishableKeyValue;
    this.intentEndpoint = this.intentEndpointValue;
    this.processPaymentEndpoint = this.processPaymentEndpointValue;
    this.redirectURL = this.redirectUrlValue;
    this.billingDetails = this.billingDetailsValue;
    this.orderNumber = this.orderNumberValue;
    this.errorMessage = this.errorMessageValue;
    this.walletPaymentSourceId = this.walletPaymentSourceIdValue;
    this.walletPaymentSourceType = this.walletPaymentSourceTypeValue;
  }

  redirectToSuccess() {
    const url = new URL(this.redirectURL);
    url.searchParams.set('redirect_status', 'succeeded');
    url.searchParams.set('payment_intent', this.intent.id);
    url.searchParams.set(
      'payment_intent_client_secret',
      this.intent.clientSecret,
    );
    window.location.replace(url);
  }

  async connect() {
    this.submitButtonTarget.addEventListener('click', async (event) => {
      try {
        event.preventDefault();

        this.fireEvent('payment-started');
        this.intent = await this.updatePaymentIntent();
        const { success } = await this.processPayment();

        if (success === false) {
          this.redirectToCart();

          return;
        }

        loadStripe.setLoadParameters({
          advancedFraudSignals: this.advancedFraudDetectionValue,
        });
        this.stripe = await loadStripe(this.publishableKey);

        if (this.walletPaymentSourceType === 'card') {
          const { error } = await this.stripe.confirmCardPayment(
            this.intent.clientSecret,
          );

          this.manageResponse(error);
        } else if (this.walletPaymentSourceType === 'sepa_debit') {
          const { error } = await this.stripe.confirmSepaDebitPayment(
            this.intent.clientSecret,
          );

          this.manageResponse(error);
        }
      } catch (error) {
        this.showError(this.errorMessage);
      }
    });
  }

  manageResponse(error) {
    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        this.showError(error.message);
      } else {
        this.showError(this.errorMessage);
      }
    } else {
      this.redirectToSuccess();
    }
  }

  redirectToCart() {
    const url = new URL(this.cartUrlValue);
    window.location.replace(url);
  }

  showError(error) {
    const message = error.message || error;

    this.errorsTarget.textContent = message;

    this.fireEvent('payment-finished');
  }

  showSubmitButton() {
    this.submitButtonTarget.classList.remove('hidden');
  }

  hideSubmitButton() {
    this.submitButtonTarget.classList.add('hidden');
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false;
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true;
  }

  async updatePaymentIntent() {
    const result = await this.request(this.intentEndpoint, 'PATCH', {
      order_number: this.orderNumber,
      authenticity_token: this.authToken,
      wallet_payment_source_id: this.walletPaymentSourceId,
    });

    if (result.error) {
      throw new StripeError(result.error);
    }

    return result;
  }

  async processPayment() {
    const result = await this.request(
      `${this.processPaymentEndpoint}`,
      'PATCH',
      {
        order_number: this.orderNumber,
        authenticity_token: this.authToken,
      },
    );

    if (result.error) {
      throw new StripeError(result.error);
    }

    return result;
  }

  async request(url, method, body = null) {
    const response = await fetch(url, {
      method,
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new StripeError('Request Error');
    }

    const result = await response.json().catch(() => {
      throw new StripeError('Parse error');
    });

    return result;
  }
}
