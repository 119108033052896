import { Navigation, Pagination, Manipulation } from 'swiper/modules';
import SliderController from '../../slider_component/slider_controller';

export default class extends SliderController {
  static swiperModules = [Navigation, Pagination, Manipulation];

  static targets = [
    'buttonNext',
    'buttonPrev',
    'pagination',
    'slideContainer',
    'variantImagePreview',
    'variantImagePreviewPlaceholder',
    'wrapper',
  ];

  static values = {
    initialOptionValueIds: Array,
    initialSlideIndex: Number,
    stickySlider: Boolean,
  };

  previousSlideIndex = 0;

  lastSectionShown = null;

  static outlets = [
    'containers--product-configurations--header-single-step-component--scroll',
  ];

  initialize() {
    super.initialize();
    this.addSlideChangeListener();
  }

  filterSlidesOnResize() {
    this.filterSlides(this.optionValueIds);
  }

  filterSlides(optionValueIds) {
    const currentScreenSize = this.isMobile() ? 'mobile' : 'desktop';

    if (
      this.optionValueIds &&
      this.optionValueIds.toString() === optionValueIds.toString() &&
      this.currentScreenSize === currentScreenSize
    ) {
      return;
    }

    const slides = this.getMatchingSlides(optionValueIds, currentScreenSize);
    this.swiper.removeAllSlides();
    this.swiper.appendSlide(slides);
    this.swiper.slideTo(this.initialSlideIndexValue, 0);

    this.optionValueIds = optionValueIds;
    this.currentScreenSize = currentScreenSize;
  }

  getSlideContainerTargetsForScreenResolution() {
    const currentScreenSize = this.isMobile() ? 'mobile' : 'desktop';

    return this.slideContainerTargets.filter((slideContainer) => {
      const { screenSize } = slideContainer.dataset;

      return screenSize === currentScreenSize || screenSize === 'all_sizes';
    });
  }

  getMatchingSlides(optionValueIds) {
    const slides = [];
    const isSelected = (id) => optionValueIds.includes(id);

    this.sectionReferences = {};

    this.getSlideContainerTargetsForScreenResolution().forEach(
      (slideContainer) => {
        const {
          dataset: { sectionReference },
        } = slideContainer;

        const pictureOptionValueIds = JSON.parse(
          slideContainer.dataset.optionValueIds,
        );

        if (sectionReference) {
          this.sectionReferences[sectionReference] = slides.length;
        }

        if (pictureOptionValueIds.every(isSelected)) {
          slides.push(slideContainer.innerHTML);
        }
      },
    );

    return slides;
  }

  getOffsetHeight() {
    return this.stickySliderValue ? this.element.offsetHeight : 0;
  }

  connect() {
    super.connect();
    this.filterSlides(this.initialOptionValueIdsValue);

    if (this.stickySliderValue) {
      this.updateSlider();
      ['resize', 'headerVisibilityChanged'].forEach((event) => {
        this.onEvent(event, this.updateSlider.bind(this));
      });
    }
    this.onEvent('resize', this.filterSlidesOnResize.bind(this));

    this.variantImagePreviewTarget.addEventListener('load', () => {
      if (this.variantImagePreviewTarget.dataset.enabled === 'true') {
        this.variantImagePreviewTarget.classList.remove('hidden');
        this.variantImagePreviewPlaceholderTarget.classList.add('hidden');
      }
    });
  }

  showVariantImage(imageUrl, section) {
    this.variantImagePreviewTarget.dataset.section = section;
    this.variantImagePreviewTarget.dataset.enabled = 'true';
    this.variantImagePreviewTarget.src = imageUrl;
    this.variantImagePreviewPlaceholderTarget.classList.remove('hidden');
    this.wrapperTarget.classList.add('hidden');
    this.paginationTarget.classList.add('xl:hidden');
    this.hideButtons();
  }

  hideVariantImage() {
    this.variantImagePreviewTarget.dataset.section = null;
    this.variantImagePreviewTarget.dataset.enabled = null;
    this.variantImagePreviewTarget.classList.add('hidden');
    this.variantImagePreviewPlaceholderTarget.classList.add('hidden');
    this.wrapperTarget.classList.remove('hidden');
    this.paginationTarget.classList.remove('xl:hidden');
    this.showButtons();
  }

  setSliderData() {
    const slides = [];

    this.sectionReferences = {};

    this.slideContainerTargets.forEach((slideContainer) => {
      const {
        dataset: { sectionReference },
      } = slideContainer;

      if (sectionReference) {
        this.sectionReferences[sectionReference] = slides.length;
      }

      slides.push(slideContainer.innerHTML);
    });

    this.swiper.removeAllSlides();
    this.swiper.appendSlide(slides);
    this.swiper.slideTo(this.initialSlideIndexValue, 0);
  }

  addSlideChangeListener() {
    this.swiper.on('init', () => {
      this.swiper.on('slideChange', () => {
        this.emitConfigurationAssetDisplayedEvent();
      });
    });
  }

  emitConfigurationAssetDisplayedEvent() {
    if (!this.isSlideChanged()) {
      return;
    }

    const currentSlide = this.getCurrentSlide();
    if (currentSlide == null) {
      return;
    }

    const { assetId } = currentSlide.dataset;
    this.fireEvent('configurationAssetDisplayed', { assetId });
  }

  isSlideChanged() {
    const activeIndex = this.swiper.realIndex;
    const slideDidChange = this.previousSlideIndex !== this.swiper.realIndex;
    this.previousSlideIndex = activeIndex;

    return slideDidChange;
  }

  getCurrentSlide() {
    const activeIndex = this.swiper.realIndex;
    return this.getSlideContainerTargetsForScreenResolution()[activeIndex];
  }

  onSectionChanged(event) {
    const sectionName = event.detail;
    if (this.lastSectionShown === sectionName) return;

    this.lastSectionShown = sectionName;
    if (this.variantImagePreviewTarget.dataset.section === sectionName) return;

    const index = this.sectionReferences[event.detail];
    if (index != null) {
      this.swiper.slideTo(index, 0);
    }
    this.hideVariantImage();
  }

  updateSlider() {
    const headerHeight = this.headerOutlet.getHeight();
    const elementParent = this.element.parentElement;

    if (this.isLargeDesktop()) {
      elementParent.classList.remove('z-20', 'sticky');
      elementParent.style.top = '0';
    } else {
      elementParent.classList.add('z-20', 'sticky');
      const currentTop = elementParent.style.top;

      if (currentTop !== headerHeight) {
        elementParent.style.top = `${headerHeight}px`;
      }
    }
  }

  resizeImage(image, targetHeight, swiperWidth) {
    const aspectRatio = image.naturalWidth / image.naturalHeight;
    const targetWidth = targetHeight * aspectRatio;
    const leftOffset = (swiperWidth - targetWidth) / 2;

    image.style.width = `${targetWidth}px`;
    image.style.height = `${targetHeight}px`;
    image.style.left = `${leftOffset}px`;
  }

  resizeVideo(video, targetHeight, swiperWidth) {
    const aspectRatio = video.width / video.height;
    const targetWidth = targetHeight * aspectRatio;
    const leftOffset = (swiperWidth - targetWidth) / 2;

    video.style.width = `${targetWidth}px`;
    video.style.height = `${targetHeight}px`;
    video.style.left = `${leftOffset}px`;
  }

  get headerOutlet() {
    return this
      .containersProductConfigurationsHeaderSingleStepComponentScrollOutlet;
  }

  hideButtons() {
    this.buttonPrevTarget.classList.add('hidden');
    this.buttonNextTarget.classList.add('hidden');
  }

  showButtons() {
    this.buttonPrevTarget.classList.remove('hidden');
    this.buttonNextTarget.classList.remove('hidden');
  }
}
