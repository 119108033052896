import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['button', 'buttonContainer'];

  static values = {
    buttonText: String,
    buttonLink: String,
  };

  static classes = ['animateIn', 'animateOut', 'hidden'];

  /**
   * Sets the visibility state of the navigation button in the slider navigation.
   * The sticky slider navigation button will be visible if there is no duplicate button above it visible in the viewport.
   */
  setNavButtonVisibility() {
    if (this.isDuplicateButtonAboveVisible()) {
      this.buttonContainerTarget.classList.add(...this.animateOutClasses);
    } else {
      this.buttonContainerTarget.classList.remove(...this.animateOutClasses);
      this.buttonContainerTarget.classList.add(...this.animateInClasses);
      this.buttonContainerTarget.classList.remove(...this.hiddenClasses);
    }
  }

  /**
   * Tests if there is a duplicate button visible in the viewport above the slider  navigation's button.
   * @returns {Boolean} `true` if there is another button with the same HREF and text content as the navigation button.
   */
  isDuplicateButtonAboveVisible() {
    return !!this.findVisibleButtons(
      this.buttonLinkValue,
      this.buttonTextValue,
    ).filter(
      (button) =>
        button !== this.buttonTarget &&
        button.getBoundingClientRect().top <
          this.element.getBoundingClientRect().top,
    ).length;
  }

  /**
   * Finds buttons in the viewport based on their HREF and text content.
   * @param {String} href  The HREF value to match.
   * @param {String} textContent The text content of the button to match.
   * @returns An array of button elements visible in the viewport.
   */
  findVisibleButtons(href, textContent) {
    const buttons = document.querySelectorAll(`a[href="${href}"], button`);

    return Array.from(buttons).filter(
      (button) =>
        button.innerText.trim() === textContent.trim() &&
        this.isElementInViewport(button),
    );
  }

  /**
   * Tests if an element is visible in the browser viewport.
   * @param {Element} element The element to check visibility for.
   * @returns
   */
  isElementInViewport(element) {
    const { top, left, bottom, right } = element.getBoundingClientRect();
    return (
      top >= 0 &&
      left >= 0 &&
      bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}
