import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['warning'];

  static values = { message: String };

  showMessage(event) {
    const input = event.target;
    if (input.type !== 'email') return;

    if (input.value.includes('@protonmail.com')) {
      this.warningTarget.innerHTML = this.messageValue;
    } else {
      this.warningTarget.innerHTML = '';
    }
  }
}
