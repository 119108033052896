import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = [
    'selectedChoice',
    'selectedChoicePlaceholder',
    'missingChoiceIcon',
    'standardSelectionChoiceIcon',
    'outlinedSelectionChoiceIcon',
    'optionInput',
  ];

  static classes = ['hiddenChoicePlaceholder', 'hiddenChoice', 'missingChoice'];

  static values = {
    currency: String,
    itemsName: String,
    requiredQuantity: Number,
    showOutlineCheckBeforeSelection: Boolean,
  };

  connect() {
    this.updateSelectionState();
  }

  updateSelectionState() {
    this.updateSelectionPresentation();
    this.updateSelectionIcon();
  }

  setMissingSelectionState() {
    this.selectedChoicePlaceholderTarget.classList.add(
      ...this.missingChoiceClasses,
    );
    this.showSelectedChoicePlaceholder();
    this.showSelectionIcon('missing');
  }

  updateSelectionPresentation() {
    if (this.isAnyItemSelected()) {
      const presentationHtml = this.getPresentationHtml();
      this.setSelectedChoicePresentation(presentationHtml);
      this.hideSelectedChoicePlaceholder();
    } else {
      this.showSelectedChoicePlaceholder();
    }
  }

  updateSelectionIcon() {
    const quantity = this.getTotalQuantity();
    if (quantity === 0 && this.showOutlineCheckBeforeSelectionValue) {
      this.showSelectionIcon('half');
    } else if (quantity >= this.requiredQuantityValue) {
      this.showSelectionIcon('full');
    } else {
      this.hideSelectionIcons();
    }
  }

  setSelectedChoicePresentation(presentationHtml) {
    this.selectedChoiceTarget.innerHTML = presentationHtml;
    this.selectedChoiceTarget.classList.remove(this.hiddenChoiceClass);
  }

  hideSelectedChoicePlaceholder() {
    this.selectedChoicePlaceholderTarget.classList.add(
      this.hiddenChoicePlaceholderClass,
    );
  }

  showSelectedChoicePlaceholder() {
    this.selectedChoiceTarget.innerHTML = '';
    this.selectedChoiceTarget.classList.add(this.hiddenChoiceClass);
    this.selectedChoicePlaceholderTarget.classList.remove(
      this.hiddenChoicePlaceholderClass,
    );
  }

  showSelectionIcon(selectionType) {
    let icon;
    switch (selectionType) {
      case 'full':
        icon = this.standardSelectionChoiceIconTarget;
        break;
      case 'half':
        icon = this.outlinedSelectionChoiceIconTarget;
        break;
      case 'missing':
        icon = this.missingChoiceIconTarget;
        break;
      default:
        return;
    }

    this.hideSelectionIcons();
    icon.classList.remove(...this.hiddenChoiceClasses);
  }

  hideSelectionIcons() {
    this.missingChoiceIconTarget.classList.add(...this.hiddenChoiceClasses);
    this.standardSelectionChoiceIconTarget.classList.add(
      ...this.hiddenChoiceClasses,
    );
    this.outlinedSelectionChoiceIconTarget.classList.add(
      ...this.hiddenChoiceClasses,
    );
  }

  getPresentationHtml() {
    let totalPrice = 0;
    let totalQuantity = 0;

    this.optionInputTargets.forEach((target) => {
      const price = parseFloat(target.dataset.price);
      const quantity = parseInt(target.value, 10);

      totalPrice += price * quantity;
      totalQuantity += quantity;
    });

    const formattedPrice = this.formatPrice(
      totalPrice,
      this.currencyValue,
      false,
    );

    return `${totalQuantity} x ${this.itemsNameValue}&nbsp;&nbsp;${formattedPrice}`;
  }

  isAnyItemSelected() {
    return this.optionInputTargets.find(
      (target) => parseInt(target.value, 10) > 0,
    );
  }

  getTotalQuantity() {
    let totalQuantity = 0;
    this.optionInputTargets.forEach((element) => {
      const value = element.value;
      totalQuantity += parseInt(value, 10);
    });

    return totalQuantity;
  }

  get isSelectedQuantityEnough() {
    return this.getTotalQuantity() >= this.requiredQuantityValue;
  }

  get isExpansionCards() {
    return this.itemsNameValue === 'Expansion Cards';
  }
}
