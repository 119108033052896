import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  connect() {
    this.iframe = this.element.querySelector('iframe');
    if (!this.iframe) return;

    if (!this.iframe.src) this.iframe.src = this.iframe.dataset.src;
  }
}
