import { get } from '@rails/request.js';
import debounce from 'lodash.debounce';
import Rails from '@rails/ujs';
import { Controller } from '@hotwired/stimulus';
import Meta from '../../assets/javascript/application/meta';

const tailwindConfig = preval`
      const resolveConfig = require('tailwindcss/resolveConfig');
      const tailwindConfig = require('../../../tailwind.config');
      module.exports = resolveConfig(tailwindConfig);
    `;

export default class extends Controller {
  eventListeners = [];

  disconnect() {
    if (this.eventListeners.length) {
      this.eventListeners.forEach((listener) => {
        window.removeEventListener(...listener);
      });

      this.eventListeners = [];
    }
  }

  getJsTarget(name) {
    return this.getChildJsTarget(document, name);
  }

  getChildJsTarget(parent, name) {
    return parent.querySelector(`[data-js-target="${name}"]`);
  }

  getCurrentUrlAnchor() {
    return window.location.hash.substr(1);
  }

  getJsTargets(name) {
    return this.getChildJsTargets(document, name);
  }

  getChildJsTargets(parent, name) {
    return parent.querySelectorAll(`[data-js-target="${name}"]`);
  }

  debounce(action) {
    return debounce(action, 600);
  }

  disableElement(target) {
    Rails.disableElement(target);
  }

  enableElement(target) {
    Rails.enableElement(target);
  }

  fireEvent(name, detail = null, element = window) {
    const event = new CustomEvent(name, { detail });
    element.dispatchEvent(event);
  }

  onEvent(name, callback, options) {
    this.eventListeners.push([name, callback, options]);
    window.addEventListener(name, callback, options);
  }

  submitClosestForm(target) {
    this.submitForm(target.closest('form'));
  }

  submitForm(target) {
    Rails.fire(target, 'submit');
  }

  getBreakpointLg() {
    return parseInt(tailwindConfig.theme.screens.lg.replace('px', ''), 10);
  }

  getBreakpointMd() {
    return parseInt(tailwindConfig.theme.screens.md.replace('px', ''), 10);
  }

  getBreakpointXl() {
    return parseInt(tailwindConfig.theme.screens.xl.replace('px', ''), 10);
  }

  isMobile() {
    return window.innerWidth < this.getBreakpointMd();
  }

  isTablet() {
    return (
      window.innerWidth >= this.getBreakpointMd() &&
      window.innerWidth < this.getBreakpointLg()
    );
  }

  isDesktop() {
    return (
      window.innerWidth >= this.getBreakpointLg() && !this.isLargeDesktop()
    );
  }

  isLargeDesktop() {
    return window.innerWidth >= this.getBreakpointXl();
  }

  updateURLParam(url, param, value) {
    if (value === '') {
      url.searchParams.delete(param);
    } else {
      url.searchParams.set(param, value);
    }

    return url.toString();
  }

  showSnackbar(message, messageType = null, linkText = null, linkUrl = null) {
    const snackbarTemplate = document.getElementById(
      'snackbar-template-container',
    ).children[0];
    const snackbarElement = snackbarTemplate.cloneNode(true);
    snackbarElement.querySelector('[data-js-target="content"]').innerHTML =
      message;

    if (linkText && linkUrl) {
      const link = snackbarElement.querySelector('[data-js-target="link"]');
      link.href = linkUrl;
      link.innerHTML = linkText;
      link.classList.remove('hidden');
    }

    if (messageType) {
      snackbarElement.classList.add(messageType);
    }

    const snackbarContainer = document.getElementById('snackbar-container');
    const header = document.querySelector('header');

    snackbarContainer.append(snackbarElement);
    snackbarContainer.style.top = `${header.offsetHeight + 20}px`;
  }

  formatPrice(price, currency, deltaPrice = true, removeDecimals = true) {
    if ((price === 0 && deltaPrice) || Number.isNaN(price)) {
      return '';
    }

    const locale = Meta.findByName('language');
    const options = {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency,
    };

    if (removeDecimals) {
      options.maximumFractionDigits = 0;
    }

    if (deltaPrice) {
      options.signDisplay = 'always';
    }

    try {
      const numberFormat = new Intl.NumberFormat(locale, options);
      return numberFormat.format(price);
    } catch (error) {
      return price;
    }
  }

  getLocale() {
    if (!this.locale) {
      const language = Meta.findByName('language');
      const country = Meta.findByName('country');
      this.locale = `${language}-${country}`;
    }

    return this.locale;
  }

  async fetchTurboStreamUpdate(url) {
    if (this.abortFetch) {
      this.abortFetch();
    }

    const controller = new AbortController();
    const { signal } = controller;
    this.abortFetch = () => controller.abort();

    Turbo.navigator.delegate.adapter.showProgressBar();

    try {
      await get(url, {
        signal,
        responseKind: 'turbo-stream',
      });
    } catch (err) {
      if (err.code !== DOMException.ABORT_ERR) {
        Turbo.navigator.delegate.adapter.progressBar.hide();
        throw err;
      }
    }

    Turbo.navigator.history.push(url);

    Turbo.navigator.delegate.adapter.progressBar.hide();
  }

  isVisible() {
    // Browser support for checkVisibility is limited
    if ('checkVisibility' in this.element) {
      return this.element.checkVisibility();
    }
    return this.element.offsetParent != null;
  }

  saveScrollPosition(element) {
    if (!window.scrollPositions) {
      window.scrollPositions = [];
    }
    window.scrollPositions.push({
      id: element.id,
      scrollTop: element.scrollTop,
    });
  }

  restoreScrollPositions() {
    if (!window.scrollPositions) return;

    window.scrollPositions.forEach((position) => {
      const element = document.getElementById(position.id);
      if (!element) return;

      element.scrollTop = position.scrollTop;
    });
    window.scrollPositions = [];
  }
}
