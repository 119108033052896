import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['input'];

  static outlets = [
    'containers--product-configurations--subsection-option-component--subsection-option',
    'containers--product-configurations--slider-component--slider',
  ];

  static values = {
    sectionName: String,
    selectionsToMake: Number,
    choicePlaceHolder: String,
    unselectedSelectionMessage: String,
    configuratorPreviewImageUrl: String,
    showVariantImageOnSelection: Boolean,
    disabledStyles: String,
  };

  connect() {
    this.currentSectionTotal = this.selectedTotalPrice();
    this.checkValidation();
  }

  onSubsectionOptionSelection({ detail: { imageUrl } }) {
    this.dispatchValidation();

    const delta = this.selectedTotalPrice() - this.currentSectionTotal;
    this.currentSectionTotal = this.selectedTotalPrice();
    this.element.dataset.deltaPrice = delta;
    this.element.dataset.price = parseFloat(this.element.dataset.price) + delta;
    this.inputTarget.dataset.price = this.element.dataset.price;
    this.inputTarget.dispatchEvent(new Event('input', { bubbles: true }));
    this.showVariantPreviewImage(imageUrl);
  }

  onMainOptionSelection() {
    this.checkValidation();
    this.showVariantPreviewImage(this.configuratorPreviewImageUrlValue);
  }

  checkValidation() {
    this.subsectionComponentOutlets.forEach((outlet) => {
      outlet.updateVariantVisibility();
    });
  }

  showVariantPreviewImage(imageUrl) {
    if (this.showVariantImageOnSelectionValue && imageUrl) {
      this.sliderOutlet.showVariantImage(imageUrl, this.sectionNameValue);
    }
  }

  updateVariantVisibility() {
    this.element.classList.toggle('selected', this.inputTarget.checked);
    if (this.inputTarget.checked) {
      this.dispatchValidation();
      this.scrollToOption();
    }
  }

  scrollToOption() {
    this.element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  isValid() {
    if (!this.inputTarget.checked) {
      return true;
    }

    return this.selectedOptionCount() === this.selectionsToMakeValue;
  }

  dispatchValidation() {
    const valid = this.isValid();
    this.dispatch('validation', {
      detail: {
        isValid: valid,
      },
    });

    this.dispatch('validation-text-change', {
      detail: {
        unselectedSelectionMessage: this.unselectedSelectionMessageValue,
      },
    });

    this.element.dataset.presentationHtml = this.choicePresentation();
    this.element.dataset.incomplete = !valid;
    this.element.dispatchEvent(new Event('input', { bubbles: true }));
  }

  choicePresentation() {
    const total = this.selectedTotalPrice();
    if (this.selectedOptionCount() === 0 || total === 0) {
      return this.choicePlaceHolderValue.substring(
        0,
        this.choicePlaceHolderValue.indexOf('&nbsp;'),
      );
    }

    return `${this.choicePlaceHolderValue.replace('0', total)}`;
  }

  selectedOptionCount() {
    return (
      this.element.querySelectorAll('input[type=radio]:checked').length - 1
    );
  }

  selectedTotalPrice() {
    return Array.from(this.element.querySelectorAll('[data-price]')).reduce(
      (total, input) => {
        const checked = input.querySelector('input[type="radio"]:checked');

        return checked ? total + parseInt(input.dataset.price, 10) : total;
      },
      0,
    );
  }

  disableSubsection() {
    this.inputTarget.disabled = true;
    const disabledStyles = this.disabledStylesValue.split(' ');
    this.element.classList.add(...disabledStyles);
  }

  enableSubsection() {
    this.inputTarget.disabled = false;
    const disabledStyles = this.disabledStylesValue.split(' ');
    this.element.classList.remove(...disabledStyles);
  }

  get subsectionComponentOutlets() {
    return this
      .containersProductConfigurationsSubsectionOptionComponentSubsectionOptionOutlets;
  }

  get sliderOutlet() {
    return this.containersProductConfigurationsSliderComponentSliderOutlet;
  }
}
