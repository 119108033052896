import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['button', 'container'];

  static classes = ['hidden', 'animateIn', 'animateOut'];

  static outlets = ['elements--tabs-navigation-component--tabs'];

  connect() {
    this.connectMutationObserver();
  }

  connectMutationObserver() {
    if (this.isDesktop()) {
      this.mobileContainerTargetObserver =
        this.mobileContainerTargetObserver?.disconnect();
      return;
    }

    if (!this.mobileContainerTargetObserver) {
      this.mobileContainerTargetObserver = this.buildMutationObserver(
        this.containerTarget,
      );
    }
  }

  disconnect() {
    this.mobileContainerTargetObserver =
      this.mobileContainerTargetObserver?.disconnect();
  }

  buildMutationObserver(...targets) {
    if (targets.length === 0) {
      return undefined;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Removes the hidden class on first scroll to handle when the target is at the top of the page.
          // This prevents the element from fading in on first load instead of staying hidden
          if (!entry.isIntersecting) {
            this.buttonTarget.classList.remove(this.hiddenClass);
          }

          this.buttonTarget.classList.toggle(
            this.animateInClass,
            !entry.isIntersecting,
          );

          this.animateOutClasses.forEach((classname) => {
            this.buttonTarget.classList.toggle(
              classname,
              !this.buttonTarget.classList.contains(this.hiddenClass) &&
                entry.isIntersecting,
            );
          });
        });
      },
      {
        rootMargin: `-${this.tabsOutlet.navigationContainerTarget.offsetHeight}px`,
      },
    );

    targets.forEach((target) => observer.observe(target));

    return observer;
  }

  get tabsOutlet() {
    return this.elementsTabsNavigationComponentTabsOutlet;
  }
}
