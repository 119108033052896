import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['modelSelector'];

  stickyModelSelector() {
    const footer = document.querySelector('footer');
    const header = document.querySelector('header');

    if (
      window.scrollY >=
        this.modelSelectorTarget.offsetTop + header.offsetHeight &&
      window.scrollY < footer.offsetTop
    ) {
      this.stickToTop();
    } else {
      this.unstick();
    }
  }

  stickToTop() {
    this.element.classList.add('is-sticky');
    document.querySelector('header').classList.remove('sticky');
  }

  unstick() {
    this.element.classList.remove('is-sticky');
    document.querySelector('header').classList.add('sticky');
  }
}
