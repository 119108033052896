import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['option'];

  static values = {
    currency: String,
    removeDecimals: Boolean,
  };

  updateSectionVariantPrices(event, updatePrice = true) {
    const selectedPrice = parseFloat(event.currentTarget.dataset.price);

    this.optionTargets.forEach((option) => {
      if (option.querySelector('input[type=radio]').disabled) {
        return;
      }

      const deltaPrice = parseFloat(option.dataset.price) - selectedPrice;

      option.dataset.deltaPrice = deltaPrice;
      if (updatePrice && this.getPriceSpan(option)) {
        this.getPriceSpan(option).innerHTML = this.formatPrice(
          deltaPrice,
          this.currencyValue,
        );
      }
    });
  }

  selectVariant(event) {
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }

    this.select(event, !this.prebuiltValue);

    const variantPrice =
      this.selectedVariantPriceValue +
      parseFloat(event.currentTarget.dataset.price);

    this.getPriceSpan(event.currentTarget).innerHTML = this.formatPrice(
      variantPrice === this.selectedVariantPriceValue
        ? this.selectedVariantPriceValue
        : variantPrice,
      this.currencyValue,
      false,
    );

    if (!this.prebuiltValue) {
      document
        .querySelectorAll('[data-js-target="option-strikethrough-price"]')
        .forEach((element) => element.classList.add('hidden'));

      this.getStrikethroughPriceSpan(event.currentTarget).classList.remove(
        'hidden',
      );
    }
  }

  selectMultipleSelection(event) {
    const selectedPrice = parseFloat(event.currentTarget.dataset.price);
    const selectedQuantity = event.currentTarget.value;
    const currentQuantity = parseFloat(event.currentTarget.dataset.quantity);
    const deltaQuantity = selectedQuantity - currentQuantity;

    event.currentTarget.dataset.quantity = selectedQuantity;

    this.updateTotal(selectedPrice * deltaQuantity);
  }

  updateTotal(deltaPrice) {
    const footerTotals = this.getFooterSpans();

    footerTotals.forEach((footerTotal) => {
      const { total, removeDecimals } = footerTotal.dataset;
      const newPrice = parseFloat(total) + deltaPrice;

      footerTotal.dataset.total = newPrice;
      footerTotal.innerHTML = this.formatPrice(
        footerTotal.dataset.total,
        this.currencyValue,
        false,
        removeDecimals || this.removeDecimalsValue,
      );
    });
  }

  getFooterSpans() {
    return this.getJsTargets('product-configuration-total');
  }

  getPriceSpan(option) {
    return this.getChildJsTarget(option, 'option-price');
  }

  getStrikethroughPriceSpan(option) {
    return this.getChildJsTarget(option, 'option-strikethrough-price');
  }
}
