import Swiper from 'swiper';
import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['container'];

  initialize() {
    this.swiper = new Swiper(this.containerTarget, {
      init: false,
      slidesPerView: 1.4,
      spaceBetween: 16,
      breakpoints: {
        [this.getBreakpointLg()]: {
          allowTouchMove: false,
          preventClicks: false,
          slidesPerView: 3,
        },
      },
      preloadImages: false,
    });
  }

  connect() {
    this.swiper?.init();
  }

  update() {
    this.swiper.update();
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }
}
