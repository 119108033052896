import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  connect() {
    this.setupActiveSectionObserver();
  }

  disconnect() {
    this.disconnectActiveSectionObserver();
  }

  setupActiveSectionObserver() {
    this.sectionData = {};

    this.observer = new IntersectionObserver(
      (entries, opts) => {
        this.onIntersection(entries, opts);
      },
      {
        threshold: 0.5,
      },
    );

    this.element
      .querySelectorAll(
        '.product-configuration-section[data-section-reference]:not([data-section-reference=""])',
      )
      .forEach((element) => {
        this.observer.observe(element);
      });
  }

  disconnectActiveSectionObserver() {
    this.observer.disconnect();
  }

  onIntersection(entries) {
    entries.forEach((entry) => {
      this.sectionData[entry.target.dataset.sectionReference] =
        entry.isIntersecting;
    });

    Object.entries(this.sectionData).every(([section, isIntersecting]) => {
      const accordion = document.querySelector(
        `#${section}-section .toggle-accordion-section-trigger`,
      );
      const isOpen =
        accordion === null || accordion.classList.contains('opened');

      if (isIntersecting && isOpen) {
        this.fireEvent('section-changed', section);
        return false;
      }
      return true;
    });
  }
}
