import Segment from './segment';
import Meta from './meta';
import User from './user';

const Analytics = {
  newsletterSubscribedEvent: 'Newsletter Subscribed',

  start() {
    const key = Meta.findByName('analytics-key');
    if (!key) return;

    Segment.load(key);
    const anonymousId = User.anonymousId();
    if (anonymousId) {
      Segment.setAnonymousId(anonymousId);
    }
  },

  identify(traits) {
    const userId = User.userId();
    if (!this.client() || !userId) return;

    this.client().identify(userId, traits);
  },

  page(name, properties) {
    if (!this.client()) return;

    this.client().page(name, this.massagedProperties(properties));
  },

  track(event, properties, options) {
    if (!this.client()) return;

    this.client().track(event, this.massagedProperties(properties), options);
  },

  reset() {
    if (!this.client()) return;

    this.client().reset();
  },

  trackDeviceOnly(event, properties) {
    const options = { integrations: { All: false, 'Facebook Pixel': true } };
    this.track(event, properties, options);
  },

  massagedProperties(properties) {
    return {
      title: document.title,
      path: document.location.pathname,
      referrer: this.getLastUrl(),
      ...properties,
    };
  },

  storeLastUrl() {
    try {
      sessionStorage.setItem('lastUrl', document.location.href);
    } catch (ignore) {}
  },

  getLastUrl() {
    try {
      const lastUrl = sessionStorage.getItem('lastUrl');

      return lastUrl || document.referrer;
    } catch (ignore) {
      return document.referrer;
    }
  },

  client() {
    return Segment.client();
  },
};

document.addEventListener('turbo:before-visit', () => {
  Analytics.storeLastUrl();
});

export default Analytics;
