import Swiper from 'swiper';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = [
    'container',
    'buttonNext',
    'buttonPrev',
    'pagination',
    'scrollbar',
  ];

  initialize() {
    this.swiper = new Swiper(this.containerTarget, {
      init: false,
      modules: [Navigation, Pagination, Scrollbar],
      navigation: {
        nextEl: this.buttonNextTarget,
        prevEl: this.buttonPrevTarget,
      },
      pagination: {
        el: this.paginationTarget,
        type: 'fraction',
      },
      scrollbar: {
        el: this.scrollbarTarget,
        dragClass: 'drag-bar',
        draggable: true,
      },
      spaceBetween: 16,
      preloadImages: false,
      slidesPerView: 1.4,
      breakpoints: {
        [this.getBreakpointLg()]: {
          slidesPerView: 3,
        },
      },
    });
  }

  connect() {
    this.swiper?.init();
  }

  update() {
    this.swiper?.update();
  }

  disconnect() {
    this.swiper?.destroy();
    this.swiper = undefined;
  }
}
