const Loader = {
  loadScript(url, onLoad = null) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.onload = onLoad;

    const tag = document.getElementsByTagName('script')[0];
    tag.parentNode.insertBefore(script, tag);
  },
};

export default Loader;
