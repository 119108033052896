import { loadStripe } from '@stripe/stripe-js/pure';
import ApplicationController from '../../../application_component/application_controller';
import Meta from '../../../../assets/javascript/application/meta';

export default class extends ApplicationController {
  static targets = ['element', 'errors', 'defaultCheckbox', 'submitButton'];

  static values = {
    clientSecret: String,
    publishableKey: String,
    billingDetails: Object,
    redirectUrl: String,
    errorMessage: String,
    advancedFraudDetection: Boolean,
  };

  initialize() {
    this.language = Meta.findByName('language');
    this.authToken = Meta.findByName('csrf-token');
    this.clientSecret = this.clientSecretValue;
    this.publishableKey = this.publishableKeyValue;
    this.billingDetails = this.billingDetailsValue;
    this.redirectURL = this.redirectUrlValue;
    this.errorMessage = this.errorMessageValue;
  }

  async connect() {
    try {
      this.hideSubmitButton();

      loadStripe.setLoadParameters({
        advancedFraudSignals: this.advancedFraudDetectionValue,
      });
      this.stripe = await loadStripe(this.publishableKey);

      this.setupStripe();
    } catch (error) {
      this.showError(this.errorMessage);
    }
  }

  setupStripe() {
    this.elements = this.stripe.elements({
      locale: this.language,
      clientSecret: this.clientSecret,
    });

    this.paymentElement = this.elements.create('payment', {
      defaultValues: {
        billingDetails: this.billingDetails,
      },
      fields: {
        billingDetails: {
          name: 'never',
          email: 'never',
          phone: 'never',
          address: {
            country: 'never',
          },
        },
      },
    });
    this.paymentElement.mount(this.elementTarget);
    this.element.style.display = 'inherit';

    this.addInputChangeListener();
    this.addSubmitButtonListener();

    this.paymentElement.on('ready', () => this.showSubmitButton());
  }

  addInputChangeListener() {
    this.paymentElement.on('change', (event) => {
      if (event.complete === true) {
        this.enableSubmitButton();
      } else {
        this.disableSubmitButton();
      }
    });
  }

  async addSubmitButtonListener() {
    this.submitButtonTarget.addEventListener('click', async (event) => {
      try {
        event.preventDefault();
        this.disableSubmitButton();

        this.fireEvent('payment-started');
        const { elements } = this;

        const { error } = await this.stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: this.redirectUrl(),
            payment_method_data: {
              billing_details: this.billingDetails,
            },
          },
        });

        if (error.type === 'card_error' || error.type === 'validation_error') {
          this.showError(error.message);
          this.enableSubmitButton();
        } else {
          this.showError(this.errorMessage);
          this.enableSubmitButton();
        }
      } catch (error) {
        this.showError(this.errorMessage);
        this.enableSubmitButton();
      }
    });
  }

  showError(error) {
    const message = error.message || error;

    this.errorsTarget.textContent = message;

    this.fireEvent('payment-finished');
  }

  redirectUrl() {
    const url = new URL(this.redirectURL);

    if (this.hasDefaultCheckboxTarget && this.defaultCheckboxTarget.checked) {
      url.searchParams.set('default', true);
    }

    return url.toString();
  }

  showSubmitButton() {
    this.submitButtonTarget.classList.remove('hidden');
  }

  hideSubmitButton() {
    this.submitButtonTarget.classList.add('hidden');
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false;
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true;
  }
}
