import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['quantity'];

  duplicate(event) {
    event.preventDefault();

    const quantityInput = this.quantityTarget.getElementsByTagName('input')[0];
    const quantity = quantityInput.value;
    quantityInput.value = parseInt(quantity, 10) + 1;
    this.submitClosestForm(quantityInput);
  }

  remove() {
    const quantityInput = this.quantityTarget.getElementsByTagName('input')[0];
    quantityInput.value = 0;
    this.submitClosestForm(quantityInput);
  }
}
