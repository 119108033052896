import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['checkbox'];

  initialize() {
    this.submitted = false;
  }

  toggleStoreCredit() {
    if (this.submitted) return;

    this.submitted = true;
    this.checkboxTarget.disabled = true;
    this.submitForm(this.element);
  }
}
