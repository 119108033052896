import ApplicationController from '../../application_component/application_controller';
import Meta from '../../../assets/javascript/application/meta';

export default class extends ApplicationController {
  static targets = [
    'addToBag',
    'description',
    'price',
    'strikethroughPrice',
    'outOfStock',
    'totalPrice',
    'variantDetail',
    'variantInput',
    'waitlist',
    'waitlistVariantInput',
  ];

  static values = {
    currency: String,
    removeDecimals: Boolean,
    priceNotPresent: String,
    variantsDataMap: Object,
  };

  connect() {
    const language = Meta.findByName('language');
    const country = Meta.findByName('country');
    this.locale = `${language}-${country}`;
    this.element.addEventListener('quantity-changed', () => {
      this.handleVariantSelection();
    });
    this.handleVariantSelection();
  }

  handleVariantSelection() {
    const variantIds = this.selectedVariants();

    if (variantIds.length > 1) {
      const price = this.sumOfVariantPrices(variantIds);
      this.updateTotalPrice(price);
    } else if (variantIds.length === 1) {
      const variantId = variantIds[0];
      const variant = this.variantsDataMapValue[variantId];
      const {
        availability,
        description,
        displayPrice,
        price,
        sku,
        strikethroughPrice,
      } = variant;

      this.updateUrl(sku);
      this.updateVariantActionComponents(availability);
      this.updateDetails(variantId);
      this.updatePrice(displayPrice);
      this.updateStrikethroughPrice(strikethroughPrice);
      this.updateTotalPrice(price);
      this.updateDescription(description);
      this.updateWaitlistVariant(variantId);
    } else {
      this.updateTotalPrice(this.priceWhenNoVariantSelected());
    }
  }

  selectedVariants() {
    return this.variantInputTargets.flatMap((variantInput) => {
      if (this.isInputSelected(variantInput)) {
        return [variantInput.value];
      }
      return [];
    });
  }

  sumOfVariantPrices(variantIds) {
    return variantIds.reduce((acc, variantId) => {
      return acc + parseFloat(this.variantsDataMapValue[variantId].price, 10);
    }, 0);
  }

  priceWhenNoVariantSelected() {
    if (this.oneVariantAvailable()) {
      return this.firstVariantPrice();
    }
    return 0;
  }

  firstVariantPrice() {
    return Object.values(this.variantsDataMapValue)[0].price;
  }

  oneVariantAvailable() {
    return Object.values(this.variantsDataMapValue).length === 1;
  }

  updateUrl(sku) {
    const currentURL = new URL(window.location);
    currentURL.searchParams.set('v', sku);

    const variantLocation = currentURL.toString();
    window.history.replaceState({}, null, variantLocation);
  }

  updateDescription(description) {
    if (!this.hasDescriptionTarget || !description) return;

    this.descriptionTarget.querySelector('.prose').innerHTML = description;
  }

  updatePrice(price) {
    if (!this.hasPriceTarget) return;

    if (price) {
      this.priceTarget.innerText = price;
    } else {
      this.priceTarget.innerText = this.priceNotPresentValue;
    }
  }

  updateStrikethroughPrice(strikethroughPrice) {
    if (!this.hasStrikethroughPriceTarget) return;

    this.strikethroughPriceTarget.innerText = strikethroughPrice;
    if (strikethroughPrice) {
      this.strikethroughPriceTarget.classList.add('mr-1.5');
    } else {
      this.strikethroughPriceTarget.classList.remove('mr-1.5');
    }
  }

  updateWaitlistVariant(variantId) {
    if (!this.hasWaitlistVariantInputTarget) return;

    this.waitlistVariantInputTarget.value = variantId;
  }

  updateVariantActionComponents(availability) {
    this.addToBagTarget.classList.toggle('hidden', availability !== 'in_stock');
    this.waitlistTarget.classList.toggle(
      'hidden',
      !['coming_soon', 'unavailable'].includes(availability),
    );
    this.outOfStockTarget.classList.toggle(
      'hidden',
      availability !== 'out_of_stock',
    );
  }

  updateDetails(id) {
    this.variantDetailTargets.forEach((detail) => {
      detail.classList.add('hidden');

      if (detail.dataset.variantId === id) {
        detail.classList.remove('hidden');
      }
    });
  }

  updateTotalPrice(price) {
    const multiplier = this.getJsTarget('quantity')?.value || 1;
    try {
      const total = parseFloat(price, 10) * parseInt(multiplier, 10);
      const formattedPrice = this.formatPrice(
        total,
        this.currencyValue,
        false,
        this.removeDecimalsValue,
      );
      this.totalPriceTarget.innerText = formattedPrice;
    } catch (ignore) {}
  }

  isInputSelected(element) {
    return (
      (element.tagName === 'INPUT' && element.checked) ||
      element.tagName === 'SELECT'
    );
  }
}
