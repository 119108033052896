import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['modal'];

  TRANSITION_SPEED = 500;

  connect() {
    this.disablePageScrolling();
    this.translateOut();
    requestAnimationFrame(() => {
      this.translateIn();
    });
  }

  close() {
    this.translateOut();
    this.enablePageScrolling();
    setTimeout(() => {
      this.element.parentElement.removeAttribute('src');
      this.element.remove();
    }, this.TRANSITION_SPEED);
  }

  translateIn() {
    this.modalTarget.style.transform = '';
  }

  translateOut() {
    this.modalTarget.style.transition = `transform ${this.TRANSITION_SPEED}ms`;
    this.modalTarget.style.transform = 'translate3d(0, 100vh, 0)';
  }

  disablePageScrolling() {
    document.body.style.overflow = 'hidden';
  }

  enablePageScrolling() {
    document.body.style.overflow = '';
  }

  closeWithKeyboard(e) {
    if (e.code === 'Escape') {
      this.close();
    }
  }

  closeBackground(e) {
    if (e && this.modalTarget.contains(e.target)) {
      return;
    }
    this.close();
  }
}
