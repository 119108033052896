import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static values = {
    initialSlide: Number,
    slidesCount: Number,
  };

  static targets = ['container', 'buttonPrev', 'buttonNext'];

  initialize() {
    const centeredFeature = this.slidesCountValue > 3;
    this.swiper = new Swiper(this.containerTarget, {
      initialSlide: this.initialSlideValue,
      centeredSlides: centeredFeature,
      centeredSlidesBounds: centeredFeature,
      init: false,
      modules: [Navigation],
      navigation: {
        nextEl: this.buttonNextTarget,
        prevEl: this.buttonPrevTarget,
      },
      slidesPerView: 'auto',
      spaceBetween: 8,
    });
  }

  connect() {
    this.callback = this.update.bind(this);
    document.addEventListener('turbo:render', this.callback);
    this.swiper?.init();
  }

  update() {
    setTimeout(() => {
      if (!this.swiper) return;

      this.swiper?.update();
      this.swiper.slideTo(this.initialSlideValue, 0);
    }, 250);
  }

  disconnect() {
    this.swiper?.destroy();
    document.removeEventListener('turbo:render', this.callback);
    this.swiper = undefined;
  }
}
