import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['content', 'accordionHeader'];

  static values = {
    name: String,
    visibility: String,
    initialVariantPreorder: Boolean,
    disableSection: Boolean,
    collapsible: Boolean,
  };

  static outlets = [
    'containers--product-configurations--accordion-component--accordion',
    'containers--product-configurations--section-component--choice',
    'containers--product-configurations--section-component--multiple-choice',
    'containers--product-configurations--variant-option-component--variant-option',
    'containers--product-configurations--multiple-selection-component--multiple-selection',
    'containers--product-configurations--none-option-component--none-option',
    'containers--product-configurations--option-value-component--option-value',
    'containers--product-configurations--subsection-option-component--subsection-option',
  ];

  forceHidden = false;

  connect() {
    this.registerForPreorderStateUpdates();
    this.selectedVariantIsPreorder = this.initialVariantPreorderValue;
    this.render();

    if (this.disableSectionValue && this.collapsibleValue) {
      this.disableSection();
    }
  }

  registerForPreorderStateUpdates() {
    this.onEvent('variantSelected', (event) => {
      const { preorder } = event.detail;

      this.selectedVariantIsPreorder = preorder;
      this.render();
    });
  }

  render() {
    if (
      this.visibilityValue === 'always' ||
      (this.selectedVariantIsPreorder && this.visibilityValue === 'preorder') ||
      (!this.selectedVariantIsPreorder && this.visibilityValue === 'in_stock')
    ) {
      this.show();
    } else {
      this.hide();
    }
  }

  hide() {
    this.element.classList.add('hidden');
    if (this.hasContentTarget) {
      this.contentTarget.disabled = true;
    }

    this.multipleSelectionOutlets.forEach((multipleSelection) => {
      multipleSelection.setQuantity(0);
    });

    this.element.dataset.selected = false;
  }

  show() {
    if (this.visibilityLocked) return;

    this.setVisibilityLock(false);
    this.element.classList.remove('hidden');
    if (this.hasContentTarget) {
      this.contentTarget.disabled = false;
    }
  }

  setVisibilityLock(visibilityLocked) {
    this.visibilityLocked = visibilityLocked;
  }

  scrollTo() {
    this.element.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  }

  selected() {
    return this.element.dataset.selected === 'true';
  }

  openSection() {
    this.accordionOutlet.open();
  }

  enableSection() {
    if (this.hasAccordionHeaderTarget) {
      this.accordionHeaderTarget.classList.remove('section--dim-text');
    }
    this.accordionOutlet?.undimAccordion();

    this.variantOptionOutlets.forEach((variantOption) => {
      if (!variantOption.availableValue) return;

      variantOption.enableInput();
    });

    this.multipleSelectionOutlets.forEach((multipleSelection) =>
      multipleSelection.enableSelect(),
    );

    this.noneOptionOutlets.forEach((noneOption) => noneOption.enable());

    this.optionValueOutlets.forEach((optionValue) => {
      if (!optionValue.availableValue) return;

      optionValue.enableInput();
      optionValue.sectionEnabled = true;
    });

    this.subsectionOptionOutlets.forEach((subsectionOption) =>
      subsectionOption.enableSubsection(),
    );
  }

  disableSection() {
    if (this.hasAccordionHeaderTarget) {
      this.accordionHeaderTarget.classList.add('section--dim-text');
    }
    this.accordionOutlet?.dimAccordion();

    this.variantOptionOutlets.forEach((variantOption) => {
      if (!variantOption.availableValue) return;

      variantOption.disableInput();
    });

    this.multipleSelectionOutlets.forEach((multipleSelection) =>
      multipleSelection.disableSelect(),
    );

    this.noneOptionOutlets.forEach((noneOption) => noneOption.disable());

    this.optionValueOutlets.forEach((optionValue) => {
      if (!optionValue.availableValue) return;

      optionValue.disableInput();
      optionValue.sectionEnabled = false;
    });

    this.subsectionOptionOutlets.forEach((subsectionOption) =>
      subsectionOption.disableSubsection(),
    );
  }

  get accordionOutlet() {
    if (
      this.hasContainersProductConfigurationsAccordionComponentAccordionOutlet
    ) {
      return this
        .containersProductConfigurationsAccordionComponentAccordionOutlet;
    }

    return undefined;
  }

  get singleChoiceOutlet() {
    if (this.hasContainersProductConfigurationsSectionComponentChoiceOutlet) {
      return this.containersProductConfigurationsSectionComponentChoiceOutlet;
    }

    return undefined;
  }

  get multipleChoiceOutlet() {
    if (
      this
        .hasContainersProductConfigurationsSectionComponentMultipleChoiceOutlet
    ) {
      return this
        .containersProductConfigurationsSectionComponentMultipleChoiceOutlet;
    }

    return undefined;
  }

  get variantOptionOutlets() {
    return this
      .containersProductConfigurationsVariantOptionComponentVariantOptionOutlets;
  }

  get multipleSelectionOutlets() {
    return this
      .containersProductConfigurationsMultipleSelectionComponentMultipleSelectionOutlets;
  }

  get noneOptionOutlets() {
    return this
      .containersProductConfigurationsNoneOptionComponentNoneOptionOutlets;
  }

  get optionValueOutlets() {
    return this
      .containersProductConfigurationsOptionValueComponentOptionValueOutlets;
  }

  get subsectionOptionOutlets() {
    return this
      .containersProductConfigurationsSubsectionOptionComponentSubsectionOptionOutlets;
  }
}
