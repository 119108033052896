import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  isPinned = true;

  prevScrollPos = null;

  connect() {
    this.prevScrollPos = window.pageYOffset;
    this.setupListeners();
  }

  setupListeners() {
    ['scroll', 'resize'].forEach((event) => {
      this.onEvent(event, () => {
        const currentScrollPos = window.pageYOffset;

        if (this.isLargeDesktop() || this.prevScrollPos > currentScrollPos) {
          this.pin();
        } else {
          this.unpin();
        }

        this.prevScrollPos = currentScrollPos;
      });
    });
  }

  pin() {
    if (this.isPinned) return;
    if (this.isScrollBeyondSections() && !this.isLargeDesktop()) return;

    this.isPinned = true;
    this.element.style.top = '0px';
    this.fireEvent('headerVisibilityChanged');
  }

  unpin() {
    if (!this.isPinned) return;
    if (!this.isScrollBeyondSections()) return;

    this.isPinned = false;
    this.fireEvent('headerVisibilityChanged');
    this.element.style.top = `-${this.element.offsetHeight}px`;
  }

  getHeight() {
    if (this.isPinned) {
      return this.element.offsetHeight;
    }

    return 0;
  }

  isScrollBeyondSections() {
    const imageSliderBottomOffset = document
      .getElementById('configuration-image-slider')
      ?.getBoundingClientRect().bottom;
    const configurationFormTopOffset = document
      .getElementById('configuration_form')
      ?.getBoundingClientRect().top;

    if (
      imageSliderBottomOffset === undefined ||
      configurationFormTopOffset === undefined
    ) {
      return false;
    }

    return configurationFormTopOffset - 20 <= imageSliderBottomOffset;
  }
}
