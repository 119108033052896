import ApplicationController from '../../../application_component/application_controller';

const tailwindConfig = preval`
      const resolveConfig = require('tailwindcss/resolveConfig');
      const tailwindConfig = require('../../../../../tailwind.config');
      module.exports = resolveConfig(tailwindConfig);
    `;
export default class extends ApplicationController {
  static targets = ['tab', 'tabHighlight', 'content', 'pdpNav'];

  static classes = [
    'slidingOutLeft',
    'slidingOutRight',
    'slidingInLeft',
    'slidingInRight',
    'hidden',
  ];

  static values = {
    currentSlug: String,
  };

  connect() {
    this.tabTargets.forEach((tab) =>
      tab.parentElement.classList.remove('relative'),
    );
  }

  selectTab(e) {
    e.preventDefault();

    const { currentTarget } = e;
    const targetSlug = currentTarget.dataset.slug;

    if (targetSlug !== this.currentSlugValue) {
      this.updateURL(currentTarget);
      this.tabTargets.forEach((tab) => tab.classList.remove('text-white'));
      this.animateTabHighlight(currentTarget);
      this.animateTabContent(currentTarget);

      this.currentSlugValue = targetSlug;
    }
  }

  animateTabContent(target) {
    const targetSlug = target.dataset.slug;
    const targetContent = this.contentTargets.find(
      (tab) => tab.dataset.slug === targetSlug,
    );

    if (
      this.getTabIndex(this.currentSlugValue) > this.getTabIndex(targetSlug)
    ) {
      this.previousTab(this.currentContent, targetContent);
    } else {
      this.nextTab(this.currentContent, targetContent);
    }

    this.pdpNavTarget.scrollIntoView({
      behavior: 'smooth',
    });
  }

  previousTab(current, target) {
    if (this.slideTabTimeout) {
      this.hideAllTargets();
      clearTimeout(this.slideTabTimeout);
    }

    target.classList.add(...this.slidingInLeftClasses);
    target.classList.remove(this.hiddenClass);
    current.classList.add(...this.slidingOutRightClasses);

    this.slideTabTimeout = setTimeout(() => {
      current.classList.remove(...this.slidingOutRightClasses);
      current.classList.add(this.hiddenClass);

      target.classList.remove(...this.slidingInLeftClasses);

      this.slideTabTimeout = null;
    }, 200);
  }

  nextTab(current, target) {
    if (this.slideTabTimeout) {
      this.hideAllTargets();
      clearTimeout(this.slideTabTimeout);
    }

    target.classList.add(...this.slidingInRightClasses);
    target.classList.remove(this.hiddenClass);
    current.classList.add(...this.slidingOutLeftClasses);

    this.slideTabTimeout = setTimeout(() => {
      current.classList.remove(...this.slidingOutLeftClasses);
      current.classList.add(this.hiddenClass);

      target.classList.remove(...this.slidingInRightClasses);

      this.slideTabTimeout = null;
    }, 200);
  }

  animateTabHighlight(target) {
    const targetLeft = target.offsetLeft;
    const currentLeft = this.tabHighlightTarget.offsetLeft;

    this.tabHighlightTarget.style.transition = '200ms ease-in-out';
    this.tabHighlightTarget.style.translate = `${Math.floor(
      targetLeft - currentLeft,
    )}px`;
    this.tabHighlightTarget.style.width = `${target.offsetWidth}px`;

    if (this.animateTabTimeout) {
      clearTimeout(this.animateTabTimeout);
    }

    this.animateTabTimeout = setTimeout(() => {
      target.classList.add('text-white');
      this.animateTabTimeout = null;
    }, 150);
  }

  getTabIndex(slug) {
    return this.tabTargets.findIndex((tab) => tab.dataset.slug === slug);
  }

  hideAllTargets() {
    this.contentTargets.forEach((target) => {
      target.classList.remove(
        ...this.slidingOutLeftClasses,
        ...this.slidingInLeftClasses,
        ...this.slidingInRightClasses,
        ...this.slidingOutRightClasses,
      );
      target.classList.add(this.hiddenClass);
    });
  }

  updateURL(target) {
    const linkEl = target.getElementsByTagName('a')[0];

    if (!linkEl) {
      return;
    }

    if (!linkEl.href) {
      throw new Error(
        `Missing href attribute on link element for ${target.dataset.slug} tab`,
      );
    }

    window.history.pushState('', '', linkEl.href);
  }

  get currentContent() {
    return this.contentTargets.find(
      (tab) => tab.dataset.slug === this.currentSlugValue,
    );
  }

  stickyTabs() {
    if (window.innerWidth <= parseInt(tailwindConfig.theme.screens.lg, 10)) {
      if (window.innerHeight >= this.pdpNavTarget.getBoundingClientRect().top) {
        this.stickToTop();
      } else {
        this.notSticked();
      }
    } else if (window.scrollY >= this.pdpNavTarget.offsetTop) {
      this.stickToTop();
    } else {
      this.notSticked();
    }
  }

  stickToTop() {
    this.element.classList.add('is-sticky');
    document.querySelector('header').classList.add('lg:static');
  }

  notSticked() {
    this.element.classList.remove('is-sticky');
    document.querySelector('header').classList.remove('lg:static');
  }
}
