import { Loader } from '@googlemaps/js-api-loader';
import ApplicationController from '../../../application_component/application_controller';
import Meta from '../../../../assets/javascript/application/meta';
import IsoCodes from '../../../../assets/javascript/components/iso-codes';

export default class extends ApplicationController {
  static targets = ['address1', 'city', 'country', 'state', 'zipcode'];

  formatByType = {
    street_number: 'short_name',
    route: 'short_name',
    locality: 'long_name',
    administrative_area_level_1: 'long_name',
    country: 'long_name',
    postal_code: 'short_name',
    postal_code_suffix: 'short_name',
  };
  territories = ['PR', 'VI', 'GU', 'MP'];
  cityCountries = ['TW', 'SI'];

  connect() {
    const key = Meta.findByName('google-apis-key');
    if (!key) return;

    const loader = new Loader({
      apiKey: key,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load().then((google) => {
      this.initPlaces(google);
    });
  }

  fillInAddress() {
    const streetNumber = this.getAddressComponent('street_number');
    const route = this.getAddressComponent('route');
    this.address1Target.value = `${streetNumber} ${route}`;
    this.cityTarget.value = this.getAddressComponent('locality');
    const postalCode = this.getAddressComponent('postal_code');
    const postalCodeSuffix = this.getAddressComponent('postal_code_suffix');
    this.zipcodeTarget.value = postalCodeSuffix
      ? `${postalCode}-${postalCodeSuffix}`
      : postalCode;
    if (!this.stateTarget || this.stateTarget.options.length == 0) return;

    // Choices JS doesn't have a way to programmatically select an option by description
    // and we don't have a reference to the Choices object without setting it to a global variable
    // in the country_controller, so the State is being selected with a mousedown event
    const state = this.getAddressComponent('administrative_area_level_1');
    this.stateTarget
      .closest('.choices')
      .querySelectorAll('.choices__item--selectable')
      .forEach((choice) => {
        if (choice.textContent === state) {
          choice.dispatchEvent(new Event('mousedown'));
        }
      });
  }

  getAddressComponent(type) {
    const components = this.place.address_components;
    if (!components) return '';

    const normalizedType = this.getNormalizedType(type);
    const result = components.find(
      (component) => component.types[0] === normalizedType,
    );
    if (!result) return '';

    return result[this.formatByType[result.types[0]]];
  }

  getNormalizedType(type) {
    const countryCode = this.getCountryCode();
    if (
      type === 'administrative_area_level_1' &&
      this.territories.includes(countryCode)
    ) {
      return 'country';
    } else if (
      type === 'locality' &&
      this.cityCountries.includes(countryCode)
    ) {
      return 'administrative_area_level_1';
    } else {
      return type;
    }
  }

  initPlaces(google) {
    const autocomplete = new google.maps.places.Autocomplete(
      this.address1Target,
      {
        fields: ['address_components'],
        types: ['address'],
        language: Meta.findByName('language'),
      },
    );
    autocomplete.addListener('place_changed', () => {
      this.place = autocomplete.getPlace();
      this.fillInAddress();
    });

    this.setRestrictions(autocomplete);
    this.countryTarget.addEventListener('change', () => {
      this.setRestrictions(autocomplete);
    });
  }

  getCountryCode() {
    const country = this.countryTarget.selectedOptions[0].text;
    return IsoCodes[country];
  }

  setRestrictions(autocomplete) {
    const countryCode = this.getCountryCode();
    if (countryCode) {
      autocomplete.setComponentRestrictions({ country: countryCode });
    } else if (autocomplete.componentRestrictions) {
      delete autocomplete.componentRestrictions.country;
    }
  }
}
