import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['name', 'shipLabel'];

  updateBatchInfoLabels(name, shipLabel) {
    this.nameTarget.innerHTML = name;
    this.shipLabelTarget.innerHTML = shipLabel;
    this.shipLabelTarget.classList.remove('placeholder');
  }
}
