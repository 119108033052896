import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['modal'];

  show() {
    document.body.classList.add('h-screen', 'overflow-hidden');

    this.modalTarget.classList.remove('hidden');
    this.modalTarget.classList.add('flex');
  }

  hide() {
    document.body.classList.remove('h-screen', 'overflow-hidden');

    this.modalTarget.classList.add('hidden');
    this.modalTarget.classList.remove('flex');
  }
}
