import Analytics from '../../../assets/javascript/application/analytics';
import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static values = { event: String, formName: String };

  track() {
    Analytics.track(this.eventValue, this.formProperties());
  }

  formProperties() {
    return [...this.element.elements].reduce(
      (properties, element) => {
        if (['hidden', 'password', 'submit'].includes(element.type)) {
          return properties;
        }

        return { ...properties, ...{ [element.id]: element.value } };
      },
      {
        form_name: this.formNameValue,
      },
    );
  }
}
