import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['radio', 'label'];

  static values = { sortName: String, selectedClass: String };

  toggleSortByLabel(e) {
    e.preventDefault();

    const {
      params: { sortValue },
    } = e;

    this.radioTargets.forEach((radio) => {
      radio.checked = radio.dataset.sort === sortValue;
    });

    this.toggleSort(e);
  }

  toggleSort(e) {
    e.preventDefault();

    const {
      params: { sortValue },
    } = e;

    this.highlightSort(sortValue);
    this.dispatchFiltersChangedEvent(sortValue);
  }

  highlightSort(sortValue) {
    this.labelTargets.forEach((label) => {
      if (label.dataset.sort === sortValue) {
        label.classList.add(this.selectedClassValue);
      } else if (label.classList.contains(this.selectedClassValue)) {
        label.classList.remove(this.selectedClassValue);
      }
    });
  }

  dispatchFiltersChangedEvent(sortValue) {
    const event = new CustomEvent('filters-changed', {
      bubbles: true,
      cancelable: true,
      detail: { filterName: this.sortNameValue, filters: [sortValue] },
    });
    this.element.dispatchEvent(event);
  }
}
