import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['line_item_selection'];

  static outlets = [
    'elements--profiles--order--edit-product-configuration-form-component--edit-product-configuration-form',
  ];

  lineItemSelectionTargetConnected(target) {
    target.addEventListener('change', () => {
      if (this.hasEditProductConfigurationFormOutlet) {
        this.editProductConfigurationFormOutlet.selectChanged();
      }
    });
  }

  lineItemSelectionTargetDisconnected(target) {
    target.removeEventListener('change', target.onChange);
  }

  get editProductConfigurationFormOutlet() {
    return this
      .elementsProfilesOrderEditProductConfigurationFormComponentEditProductConfigurationFormOutlet;
  }

  get hasEditProductConfigurationFormOutlet() {
    return this
      .hasElementsProfilesOrderEditProductConfigurationFormComponentEditProductConfigurationFormOutlet;
  }
}
