import ApplicationController from '../../../../application_component/application_controller';

const tailwindConfig = preval`
  const resolveConfig = require('tailwindcss/resolveConfig');
  const tailwindConfig = require('../../../../../../tailwind.config');
  module.exports = resolveConfig(tailwindConfig);
`;

export default class extends ApplicationController {
  static targets = ['searchInput', 'searchbar', 'submitIcon', 'clearIcon'];

  static values = { filterName: String };

  static outlets = [
    'containers--product-categories--filters--selection-component--filter',
    'pages--product-categories-component--toggle-search',
  ];

  get filterOutletElement() {
    return this
      .containersProductCategoriesFiltersSelectionComponentFilterOutletElement;
  }

  get toggleSearchOutlet() {
    return this.pagesProductCategoriesComponentToggleSearchOutlet;
  }

  connect() {
    this.showClearIcon();
  }

  submitSearch() {
    const searchText = this.searchInputTarget.value.trim();

    if (this.isInMarketplace() && this.isNotMobile()) {
      this.dispatchFiltersChangedEvent(searchText);
    } else {
      Turbo.visit(`/marketplace?search=${searchText}`);
    }

    this.toggleSearchOutlet.mobileSearchTarget.classList.remove('is-visible');
    this.submitIconTarget.classList.remove('lg:block');
    this.searchInputTarget.blur();

    this.showClearIcon();
  }

  clearSearch() {
    this.searchInputTarget.value = '';

    if (this.isNotMobile()) {
      const url = new URL(window.location);

      if (url.searchParams.has('search')) {
        url.searchParams.delete('search');
        window.location = url;
      }
    } else {
      this.searchInputTarget.focus();
    }

    this.clearIconTarget.classList.add('hidden');
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.submitSearch();
    }
  }

  dispatchFiltersChangedEvent(searchText) {
    const event = new CustomEvent('filters-changed', {
      bubbles: true,
      cancelable: true,
      detail: { filterName: 'search', filters: [searchText] },
    });
    this.filterOutletElement.dispatchEvent(event);
  }

  isInMarketplace() {
    return window.location.pathname.includes('/marketplace');
  }

  isNotMobile() {
    return window.innerWidth >= parseInt(tailwindConfig.theme.screens.lg, 10);
  }

  showSubmitIcon() {
    this.submitIconTarget.classList.add('lg:block');
  }

  showClearIcon() {
    if (this.searchInputTarget.value !== '') {
      this.clearIconTarget.classList.remove('hidden');
    } else {
      this.clearIconTarget.classList.add('hidden');
    }
  }
}
