import Swiper from 'swiper';
import { Navigation, Scrollbar } from 'swiper/modules';
import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['container', 'buttonNext', 'buttonPrev', 'scrollbar'];

  initialize() {
    this.swiper = new Swiper(this.containerTarget, {
      init: false,
      modules: [Navigation, Scrollbar],
      navigation: {
        nextEl: this.buttonNextTarget,
        prevEl: this.buttonPrevTarget,
      },
      scrollbar: {
        el: this.scrollbarTarget,
        dragClass: 'drag-bar',
        draggable: true,
      },
      spaceBetween: 25,
      preloadImages: false,
      slidesPerView: 1.4,
      breakpoints: {
        [this.getBreakpointLg()]: {
          slidesPerView: 3,
        },
      },
    });
  }

  connect() {
    this.swiper?.init();
  }

  update() {
    this.swiper?.update();
  }

  disconnect() {
    this.swiper?.destroy();
    this.swiper = undefined;
  }
}
