import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['selectAddressModalTrigger', 'newAddressModalTrigger'];

  showNewAddressModal() {
    this.selectAddressModalTriggerTarget.click();
    this.newAddressModalTriggerTarget.click();
  }
}
