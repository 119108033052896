import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['currentStep'];

  connect() {
    const smallScreen = window.matchMedia('(max-width: 640px)').matches;
    if (this.hasCurrentStepTarget && smallScreen) {
      this.currentStepTarget.scrollIntoView();

      this.hideHeaderFlash();
    }
  }

  hideHeaderFlash() {
    const headerFlash = this.getJsTarget('header-flash');
    if (headerFlash) {
      headerFlash.style.display = 'none';
    }
  }
}
