import ApplicationController from '../../../application_component/application_controller';
import Analytics from '../../../../assets/javascript/application/analytics';

const tailwindConfig = preval`
  const resolveConfig = require('tailwindcss/resolveConfig');
  const tailwindConfig = require('../../../../../tailwind.config');
  module.exports = resolveConfig(tailwindConfig);
`;

export default class extends ApplicationController {
  static targets = [
    'category',
    'clearFilters',
    'count',
    'filter',
    'filterTrigger',
    'menu',
  ];

  tempFilters = {};

  connect() {
    this.restoreScrollPositions();
  }

  toggleBodyOverflow({ target }) {
    const { checked } = target;
    document.body.classList.toggle('overflow-hidden', checked);
    document.body.classList.toggle('lg:overflow-auto', checked);
  }

  fetchWithCategory({ detail: { href } }) {
    const { search } = new URL(window.location);
    const newURL = new URL(href);
    const count = parseInt(this.countTarget.innerHTML, 10);
    newURL.search = search;

    if (
      count <= 0 &&
      /\/marketplace$/.test(window.location.pathname) &&
      newURL.searchParams.size === 1
    ) {
      newURL.search = '';
    }

    this.load(newURL);
  }

  fetchWithFilters({ detail: { filterName, filters } }) {
    const mobileScreenWidth = parseInt(tailwindConfig.theme.screens.lg, 10);
    const { checked } = this.filterTriggerTarget;

    if (checked && window.innerWidth < mobileScreenWidth) {
      this.tempFilters[filterName] = filters;
    } else {
      const newURL = new URL(window.location);

      newURL.searchParams.delete(filterName);

      if (filters?.length) {
        filters.forEach((filter) => {
          newURL.searchParams.append(filterName, filter);
        });
      }

      if (filters && filters.length > 0) {
        this.clearFiltersTarget.style.display = 'block';
      } else {
        this.clearFiltersTarget.style.display = 'none';
      }

      this.load(newURL);
    }
  }

  applyFilters() {
    const newURL = new URL(window.location);

    Object.entries(this.tempFilters).forEach(([filterName, filters]) => {
      newURL.searchParams.delete(filterName);
      if (filters?.length) {
        filters.forEach((filter) => {
          newURL.searchParams.append(filterName, filter);
        });
      }
    });

    this.tempFilters = {};
    this.closeFilterMenu();
    this.load(newURL);
  }

  closeFilterMenu() {
    if (this.filterTriggerTarget.checked) {
      this.filterTriggerTarget.checked = false;
    }

    document.body.classList.toggle('overflow-hidden');
  }

  load(url) {
    this.saveScrollPosition(this.menuTarget);
    Turbo.navigator.history.push(url);
    Turbo.visit(url, { action: 'replace' });
  }
}
