import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

const componentsContext = require.context(
  '../../../components',
  true,
  /\.(js|scss)$/,
);
componentsContext.keys().forEach(componentsContext);

const componentsControllersContext = require.context(
  '../../../components',
  true,
  /\.js$/,
);

const application = Application.start();
application.load(definitionsFromContext(componentsControllersContext));
