import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['quantity', 'addQuantityButton', 'removeQuantityButton'];

  static values = {
    minimumAllowed: Number,
    maximumAllowed: Number,
    submit: Boolean,
  };

  connect() {
    if (this.submitValue) {
      this.update = this.debounce((form) => this.submitForm(form));
    }
    this.updateButtonsState();
  }

  increment(event) {
    event.preventDefault();
    const quantity = this.quantity();
    if (quantity >= this.maximumAllowedValue) return;

    this.quantityTarget.value = quantity + 1;
    this.updateButtonsState();
    this.dispatchQuantityChangedEvent();
    this.submit(event);
  }

  decrement(event) {
    event.preventDefault();
    const quantity = this.quantity();
    if (quantity <= this.minimumAllowedValue) return;

    this.quantityTarget.value = quantity - 1;
    this.updateButtonsState();
    this.dispatchQuantityChangedEvent();
    this.submit(event);
  }

  updateButtonsState() {
    this.addQuantityButtonTarget.disabled = false;
    this.removeQuantityButtonTarget.disabled = false;

    if (this.quantity() >= this.maximumAllowedValue) {
      this.addQuantityButtonTarget.disabled = true;
    }

    if (this.quantity() <= this.minimumAllowedValue) {
      this.removeQuantityButtonTarget.disabled = true;
    }
  }

  quantity() {
    return parseInt(this.quantityTarget.value, 10);
  }

  submit(event) {
    if (this.submitValue) {
      this.update(event.currentTarget.form);
    }
  }

  dispatchQuantityChangedEvent() {
    const event = new CustomEvent('quantity-changed', {
      bubbles: true,
      cancelable: true,
    });
    this.element.dispatchEvent(event);
  }
}
