import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  currentIndex = 0;

  interval = null;

  animatedClass = 'animated-text--is-active';

  initialize() {
    this.items = Array.from(document.getElementsByClassName('animated-text'));
  }

  connect() {
    this.addFocusEventListener();
    this.createLoop();
  }

  disconnect() {
    super.disconnect();
    this.stopLoop();
  }

  createLoop() {
    this.interval = setInterval(() => this.swapText(), 3000);

    this.swapText();
  }

  swapText() {
    this.deanimateAllElements();
    this.animateElement(this.currentIndex);
    this.currentIndex = (this.currentIndex + 1) % this.items.length;
  }

  stopLoop() {
    clearInterval(this.interval);
  }

  deanimateAllElements() {
    this.items.forEach((item) => {
      item.classList.remove(this.animatedClass);
    });
  }

  animateElement(index) {
    this.items[index].classList.add(this.animatedClass);
  }

  addFocusEventListener() {
    this.onEvent('visibilitychange', () => {
      if (document.hidden) {
        this.stopLoop();
      } else {
        this.createLoop();
      }
    });
  }
}
