import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static values = { open: Boolean };

  connect() {
    this.hideModalsContentBeforePageLoad();
    this.setupModalsTriggers();
    this.setDocumentVhProperty();
    if (this.openValue) this.open();

    this.onEvent('resize', () => {
      this.debounce(() => {
        this.setDocumentVhProperty();
      });
    });
  }

  open() {
    const trigger =
      this.element.closest('.modal-content').previousElementSibling;
    if (!trigger.matches('input.modal-trigger')) {
      return;
    }
    trigger.checked = true;
    this.fireEvent('change', null, trigger);
  }

  hideModalsContentBeforePageLoad() {
    const modalContents = document.querySelectorAll('.modal-content');

    window.addEventListener('DOMContentLoaded', () => {
      modalContents.forEach((modalContent) => {
        modalContent.classList.add('hidden');
        modalContent.classList.add('-z-10');
      });

      setTimeout(() => {
        modalContents.forEach((modalContent) => {
          modalContent.classList.remove('hidden');
          modalContent.classList.remove('-z-10');
        });
      }, 0);
    });
  }

  setupModalsTriggers() {
    const modalTriggers = document.querySelectorAll('.modal-trigger');
    const stickyHeadings = document.querySelectorAll(
      '.toggle-accordion-section-trigger',
    );

    modalTriggers.forEach((trigger) => {
      trigger.addEventListener('change', () => {
        if (trigger.checked) {
          document.body.classList.add('overflow-hidden');
          trigger.nextElementSibling.classList.add('is-visible');
          stickyHeadings.forEach((heading) => {
            heading.classList.remove('sticky');
          });
        } else {
          document.body.classList.remove('overflow-hidden');
          trigger.nextElementSibling.classList.remove('is-visible');
          stickyHeadings.forEach((heading) => {
            heading.classList.add('sticky');
          });
        }
      });
    });
  }

  setDocumentVhProperty() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  preventBubbling(event) {
    event.stopPropagation();
  }
}
