import ApplicationController from '../../../../application_component/application_controller';

// TODO: Refactor this controller into a more abstract state, which can be
// consistently reused across the app for form submissions.

export default class extends ApplicationController {
  static targets = ['save'];

  connect() {
    this.disableSave();
  }

  selectChanged() {
    if (
      this.anyExpansionCardQuantitiesChanged() ||
      this.anyLineItemSelectionsChanged()
    ) {
      this.enableSave();
    } else {
      this.disableSave();
    }
  }

  anyExpansionCardQuantitiesChanged() {
    var changed = false;

    this.getJsTargets('expansion_card').forEach((select) => {
      if (select.dataset.quantity != select.value) {
        changed = true;
      }
    });
    return changed;
  }

  anyLineItemSelectionsChanged() {
    var changed = false;

    this.getJsTargets('line_item_selection').forEach((select) => {
      if (select.dataset.initialValue != select.value) {
        changed = true;
      }
    });

    return changed;
  }

  // FIXME: Due to the tight coupling between the disabled and spinner states
  // of our buttons, this only modifies the appearance of the button, without
  // actually disabling the on click behaviour.
  //
  // Additional work is required to fully disable the functionality of the
  // button on form load, while also supporting the spinner loading UX.
  enableSave() {
    this.saveTarget.className = this.saveTarget.dataset.enabledStyle;
  }

  disableSave() {
    this.saveTarget.className = this.saveTarget.dataset.disabledStyle;
  }
}
