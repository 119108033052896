import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['total'];

  static values = {
    currency: String,
    removeDecimals: Boolean,
  };

  updateTotal(amount) {
    this.totalTarget.innerHTML = this.formatPrice(
      amount,
      this.currencyValue,
      false,
      this.removeDecimalsValue,
    );
  }
}
