import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['clampedContent', 'readMoreLink'];

  connect() {
    const textClamped =
      this.clampedContentTarget.scrollHeight >
      this.clampedContentTarget.clientHeight;

    if (!textClamped) {
      this.readMoreLinkTarget.hidden = true;
    }
  }
}
