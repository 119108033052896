import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['video'];

  static values = {
    initialAssetIdDesktop: String,
    initialAssetIdMobile: String,
    assetId: String,
  };

  connect() {
    this.video = this.videoTarget.querySelector('video');

    if (this.isMobile()) {
      if (this.initialAssetIdMobileValue === this.assetIdValue) {
        this.play();
      }
    } else if (this.initialAssetIdDesktopValue === this.assetIdValue) {
      this.play();
    }

    this.addConfigurationAssetDisplayedListener();
  }

  play() {
    this.video?.play();
  }

  pause() {
    this.video?.pause();
  }

  addConfigurationAssetDisplayedListener() {
    this.onEvent(
      'configurationAssetDisplayed',
      (event) => this.onConfigurationAssetDisplayedChanged(event),
      true,
    );
  }

  disconnect() {
    super.disconnect();
  }

  isElementVisible() {
    return this.element.offsetWidth > 0 && this.element.offsetHeight > 0;
  }

  onConfigurationAssetDisplayedChanged(event) {
    const { assetId } = event.detail;

    if (assetId.toString() === this.assetIdValue.toString()) {
      this.play();
    } else {
      this.pause();
    }
  }
}
