export default class {
  constructor(record) {
    this.availability = record.availability;
    this.optionValuesMap = record.option_values_map;
    this.amount = parseFloat(record.amount);
    this.displayAmount = record.display_amount;
    this.discountPercentage = record?.discount_percentage;
    this.batchPresentation = record?.batch_presentation;
    this.batchShipLabel = record?.batch_ship_label;
    this.preorder = record.preorder;
  }

  hasOptionValues(optionValueIds) {
    return optionValueIds.every((id) => this.hasOptionValue(id));
  }

  hasOptionValue(optionValueId) {
    return Object.values(this.optionValuesMap).includes(optionValueId);
  }

  hasOptionValuesMap(optionValuesMap) {
    return this.objectsIncludesObject(this.optionValuesMap, optionValuesMap);
  }

  optionValuesMapExcept(optionTypeId) {
    const result = { ...this.optionValuesMap };
    delete result[optionTypeId];

    return result;
  }

  objectsIncludesObject(obj1, obj2) {
    return Object.entries(obj2).every(([key, value]) => obj1[key] === value);
  }

  get isAvailable() {
    return this.availability === 'in_stock';
  }
}
