import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['mobileSearch'];

  static outlets = [
    'containers--product-categories--filters--search-component--search',
  ];

  openSearch() {
    const hiddenInput = this.createHiddenInput();
    document.body.prepend(hiddenInput);
    hiddenInput.focus();
    this.mobileSearchTarget.classList.add('is-visible');
    document.body.classList.add('overflow-hidden');

    setTimeout(() => {
      this.searchOutlet.searchInputTarget.focus({ preventScroll: true });
      hiddenInput.remove();
    }, 400);
  }

  closeSearch() {
    this.mobileSearchTarget.classList.remove('is-visible');
    document.body.classList.remove('overflow-hidden');
  }

  createHiddenInput() {
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'text');
    hiddenInput.classList.add('fixed', 'h-0', 'w-0', 'opacity-0');
    return hiddenInput;
  }

  get searchOutlet() {
    return this.containersProductCategoriesFiltersSearchComponentSearchOutlet;
  }
}
