import Choices from 'choices.js';
import Analytics from '../../../../assets/javascript/application/analytics';
import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['product'];

  static values = { updatePageUrl: Boolean, productPath: String };

  connect() {
    this.select = this.initializeSelector(this.productTarget);
  }

  disconnect() {
    if (this.select) this.select.destroy();
  }

  initializeSelector(element) {
    const select = new Choices(element, {
      classNames: {
        flippedState: 'select-one',
      },
      itemSelectText: '',
      shouldSort: false,
      allowHTML: true,
    });

    select.passedElement.element.addEventListener('choice', async (event) => {
      const slug = event.detail.value;
      let newURL;

      if (this.updatePageUrlValue) {
        newURL = new URL(window.location);
        const path = newURL.pathname.split('/');
        newURL.pathname = [...path.slice(0, -1), slug].join('/');
      } else {
        newURL = `${this.productPathValue}/${slug}`;
      }

      await this.fetchTurboStreamUpdate(newURL);

      Analytics.page();
    });

    return select;
  }
}
