import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static values = {
    enabledStyle: String,
    disabledStyle: String,
  };

  connect() {
    this.updateDisabledStyle = this.updateDisabledStyle.bind(this);
    this.styleDisabled = true;
    this.disableStyle();

    this.form = this.getForm();
    this.initialData = new FormData(this.form);
    Array.from(this.form.elements).forEach((element) => {
      if (
        (element.tagName === 'INPUT' && element.type === 'text') ||
        element.tagName === 'TEXTAREA'
      ) {
        element.addEventListener('input', this.updateDisabledStyle);
      } else {
        element.addEventListener('change', this.updateDisabledStyle);
      }
    });
  }

  disableStyle() {
    this.showsAsDisabled = true;
    this.element.className = this.disabledStyleValue;
  }

  enableStyle() {
    this.showsAsDisabled = false;
    this.element.className = this.enabledStyleValue;
  }

  getForm() {
    var form = this.element.closest('form');
    if (form === null) {
      const formId = this.element.getAttribute('form');
      if (formId) {
        form = document.getElementById(formId);
      }
    }
    return form;
  }

  updateDisabledStyle() {
    const changed = this.hasDataChanged();
    if (changed && this.showsAsDisabled) {
      this.enableStyle();
    } else if (!changed && !this.showsAsDisabled) {
      this.disableStyle();
    }
  }

  hasDataChanged() {
    var actualData = new FormData(this.form);

    for (var [key, value] of actualData.entries()) {
      if (key === 'authenticity_token') continue;
      if (value !== this.initialData.get(key)) return true;
    }
    return false;
  }
}
