import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['addressForm', 'radioInput'];

  static values = { newAddress: Boolean };

  static form;

  connect() {
    this.form = this.addressFormTarget.innerHTML;
    this.toggleAddressForm(this.newAddressValue);

    this.addShowNewAddressFormListener();
  }

  hideNewAddressForm() {
    this.toggleAddressForm(false);
  }

  showNewAddressForm() {
    this.deselectUserAddresses();
    this.toggleAddressForm(true);
  }

  deselectUserAddresses() {
    this.radioInputTargets.forEach((el) => {
      el.checked = false;
    });
  }

  toggleAddressForm(showForm) {
    if (showForm) {
      this.addressFormTarget.innerHTML = this.form;
    } else {
      this.addressFormTarget.innerHTML = '';
    }
  }

  addShowNewAddressFormListener() {
    this.onEvent('showNewAddressForm', () => this.showNewAddressForm());
  }
}
