import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['optionInput', 'optionLink'];

  static values = { filterName: String };

  toggleFilterByLink(e) {
    e.preventDefault();
    const checkbox = this.optionInputTargets.find(
      (target) =>
        String(target.dataset.option) === String(e.params.optionValue),
    );

    if (checkbox) {
      checkbox.checked = !checkbox.checked;
    }

    this.toggleFilter();
  }

  toggleFilter() {
    const filters = this.optionInputTargets
      .filter((target) => target.checked)
      .map((filter) => filter.value);

    this.dispatchFiltersChangedEvent(filters);
  }

  clearFilter() {
    this.optionInputTargets
      .filter((target) => target.checked)
      ?.forEach((filter) => {
        filter.checked = false;
      });

    this.dispatchFiltersChangedEvent();
  }

  dispatchFiltersChangedEvent(filters) {
    const event = new CustomEvent('filters-changed', {
      bubbles: true,
      cancelable: true,
      detail: { filterName: `${this.filterNameValue}[]`, filters },
    });
    this.element.dispatchEvent(event);
  }
}
