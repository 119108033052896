import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static values = { initialMessages: Array };

  connect() {
    this.initialMessagesValue.forEach(([msgType, text]) => {
      this.showSnackbar(text, msgType);
    });
  }
}
