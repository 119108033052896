import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['inStockElement', 'preorderElement'];

  connect() {
    this.registerForPreorderStateUpdates();
  }

  registerForPreorderStateUpdates() {
    this.onEvent('variantSelected', (event) => {
      const { preorder } = event.detail;

      this.preorder = preorder;
      this.render();
    });
  }

  render() {
    if (this.preorder) {
      this.showPreorderElements();
    } else {
      this.showInStockElements();
    }
  }

  showPreorderElements() {
    this.inStockElementTargets.forEach((element) =>
      element.classList.add('hidden'),
    );
    this.preorderElementTargets.forEach((element) =>
      element.classList.remove('hidden'),
    );
  }

  showInStockElements() {
    this.inStockElementTargets.forEach((element) =>
      element.classList.remove('hidden'),
    );
    this.preorderElementTargets.forEach((element) =>
      element.classList.add('hidden'),
    );
  }
}
