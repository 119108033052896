import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  connect() {
    this.enable = this.enable.bind(this);
    this.disable = this.disable.bind(this);
    this.clicked = false;

    document.addEventListener('turbo:load', this.enable);
    document.addEventListener('turbo:frame-load', this.enable);
    document.addEventListener('turbo:submit-end', this.enable);
    window.addEventListener('pageshow', this.enable);

    this.form = this.getForm();
    if (this.form) {
      this.form.addEventListener('submit', this.disable);
    }
  }

  disconnect() {
    document.removeEventListener('turbo:load', this.enable);
    document.removeEventListener('turbo:frame-load', this.enable);
    document.removeEventListener('turbo:submit-end', this.enable);
    window.removeEventListener('pageshow', this.enable);
    this.form = this.getForm();
    if (this.form) {
      this.form.removeEventListener('submit', this.disable);
    }
  }

  disable(event) {
    if (this.clicked) {
      event.preventDefault();
      return;
    }
    this.clicked = true;
    requestAnimationFrame(() => this.element.setAttribute('disabled', 'true'));
    this.element.classList.add('disabled');
  }

  enable() {
    this.clicked = false;
    this.element.removeAttribute('disabled');
    this.element.classList.remove('disabled');
  }

  getForm() {
    var form = this.element.closest('form');
    if (form === null) {
      const formId = this.element.getAttribute('form');
      if (formId) {
        form = document.getElementById(formId);
      }
    }
    return form;
  }
}
