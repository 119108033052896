import Choices from 'choices.js';
import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['country', 'language', 'container'];

  static values = { availableCountriesMap: Object };

  connect() {
    const choicesOptions = {
      classNames: {
        flippedState: 'flipped-none',
      },
      itemSelectText: '',
      shouldSort: false,
      allowHTML: true,
    };

    this.countrySelector = new Choices(this.countryTarget, {
      callbackOnCreateTemplates: this.updateTemplate,
      ...choicesOptions,
    });
    this.languageSelector = new Choices(this.languageTarget, choicesOptions);
    this.containerTarget.classList.remove('invisible');
  }

  updateTemplate(template) {
    return {
      item: (_options, data) => {
        const currency = data.label.split('|')[0].slice(-3);
        const country = data.label.split('|')[0].slice(0, -3);
        const countryCode = data.value.slice(-2).toLowerCase();

        return template(`
          <div class="flex rounded-lg
            data-item data-id="${data.id}" data-value="${data.value}"
            aria-selected="${data.active}" aria-disabled="${data.disabled}">
            <span class="w-6 mr-2">
              <img width="24" height="20" src="/images/flags/${countryCode}.svg">
            </span>
            <span class="font-semibold">${country}</span>
            <span>&nbsp;${currency}</span>
          </div>
        `);
      },
      choice: ({ classNames }, data) => {
        const currency = data.label.split('|')[0].slice(-3);
        const country = data.label.split('|')[0].slice(0, -3);
        const countryCode = data.value.slice(-2).toLowerCase();

        return template(`
          <div class="flex ${classNames.item} ${classNames.itemChoice}
            ${classNames.itemSelectable}" data-choice data-choice-selectable
            data-id="${data.id}" data-value="${data.value}"
            data-test-id="${data.value}-country-option" role="option">
            <span class="w-6 mr-2">
              <img width="24" height="20" src="/images/flags/${countryCode}.svg">
            </span>
            <span class="font-semibold">${country}</span>
            <span>&nbsp;${currency}</span>
          </div>
        `);
      },
    };
  }

  async loadLanguages() {
    const countryCode = this.countryTarget.value;
    const languageCodes = await this.fetchLanguages(countryCode);
    this.languageSelector.init();
    this.languageSelector.setChoices(languageCodes, 'iso', 'name', true);
    this.languageSelector.setChoiceByValue(languageCodes[0].iso);
  }

  async fetchLanguages(countryCode) {
    return fetch(`/api/languages?country_code=${countryCode}`)
      .then((response) => response.json())
      .then(({ languages }) => languages);
  }

  disconnect() {
    if (this.countrySelector) this.countrySelector.destroy();
    if (this.languageSelector) this.languageSelector.destroy();
  }
}
