import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  connect() {
    this.element.addEventListener('change', (event) => {
      this.submitForm();
    });
  }

  submitForm() {
    var form = this.getForm();
    if (form) {
      form.requestSubmit();
    }
  }

  getForm() {
    var form = this.element.closest('form');
    if (form === null) {
      const formId = this.element.getAttribute('form');
      if (formId) {
        form = document.getElementById(formId);
      }
    }
    return form;
  }
}
