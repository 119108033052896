import Analytics from '../../../assets/javascript/application/analytics';
import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static values = { event: String };

  trackClick() {
    Analytics.track(this.eventValue);
  }
}
