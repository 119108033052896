import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static values = { expansionCardsIdsQuantities: Object };

  static outlets = [
    'containers--product-configurations--multiple-selection-component--multiple-selection',
    'containers--product-configurations--header-single-step-component--scroll',
    'containers--product-configurations--slider-component--slider',
    'containers--product-configurations--accordion-component--accordion',
    'containers--product-configurations--subsections-component--subsections',
    'pages--product-configuration-component--sections',
  ];

  updateExpansionCards(event) {
    event.preventDefault();

    const expansionCardsIdsQuantities = {
      ...this.expansionCardsIdsQuantitiesValue,
    };
    this.expansionCardOutlets.forEach((outlet) => {
      if (!outlet.isVisible()) return;

      const quantity = expansionCardsIdsQuantities[outlet.variantIdValue] || 0;

      outlet.setQuantity(quantity);
      expansionCardsIdsQuantities[outlet.variantIdValue] = 0;
    });

    const top = this.getScrollTopOffset();

    this.sectionsOutletElement.scrollTo({ top, behavior: 'smooth' });
    window.scrollTo({ top, behavior: 'smooth' });
  }

  getScrollTopOffset() {
    const headerHeight = this.headerOutlet.getHeight();
    const sliderOffsetHeight = this.isLargeDesktop()
      ? 0
      : this.sliderOutlet.getOffsetHeight();
    const accordionHeaderHeight =
      this.accordionOutlet.headerTarget.offsetHeight;
    const subsectionsPresentationHeight =
      this.subsectionsOutlet.presentationTarget.offsetTop;

    return (
      subsectionsPresentationHeight -
      accordionHeaderHeight -
      headerHeight -
      sliderOffsetHeight +
      this.extraScrollOffset()
    );
  }

  get headerOutlet() {
    return this
      .containersProductConfigurationsHeaderSingleStepComponentScrollOutlet;
  }

  get sliderOutlet() {
    return this.containersProductConfigurationsSliderComponentSliderOutlet;
  }

  get expansionCardOutlets() {
    return this
      .containersProductConfigurationsMultipleSelectionComponentMultipleSelectionOutlets;
  }

  get sectionsOutletElement() {
    return this.pagesProductConfigurationComponentSectionsOutletElement;
  }

  get accordionOutlet() {
    return this
      .containersProductConfigurationsAccordionComponentAccordionOutlet;
  }

  get subsectionsOutlet() {
    return this
      .containersProductConfigurationsSubsectionsComponentSubsectionsOutlet;
  }

  extraScrollOffset() {
    // This is kind of a hack to scroll to the correct position on desktop
    if (!this.isMobile()) return 50;

    return 0;
  }
}
