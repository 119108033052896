import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = [
    'comparison',
    'comparisonValue',
    'modelPresentation',
    'startingAtPrice',
    'inStockIcon',
    'preorderIcon',
    'shippingTime',
  ];

  static values = {
    comparisonValuesForComparisons: Object,
    optionValueHighlights: Array,
    inStockShippingTimeCopy: String,
  };

  columnComparableChanged(e) {
    const newComparableId = Number(e.target.value);
    const { columnNumber } = e.target.dataset;

    this.updateComparisonValues(newComparableId, columnNumber);
    this.updateOptionValueHighlights(newComparableId, columnNumber);
  }

  updateComparisonValues(newComparableId, columnNumber) {
    this.comparisonValueTargets.forEach((comparisonValueTarget) => {
      if (comparisonValueTarget.dataset.columnNumber === columnNumber) {
        const comparisonValuesForComparison =
          this.comparisonValuesForComparisonsValue[
            comparisonValueTarget.dataset.comparison
          ];

        const comparisonValueDataForNewComparable =
          comparisonValuesForComparison.find((comparisonValue) => {
            return comparisonValue.comparable_id === newComparableId;
          });

        comparisonValueTarget.innerHTML =
          comparisonValueDataForNewComparable.presentation;
      }
    });
  }

  updateOptionValueHighlights(newComparableId, columnNumber) {
    const highlightsForOptionValue = this.optionValueHighlightsValue.find(
      (highlights) => highlights.option_value_id === newComparableId,
    );

    this.updateTargetInColumn(
      this.modelPresentationTargets,
      columnNumber,
      (modelPresentationTarget) => {
        modelPresentationTarget.innerHTML =
          highlightsForOptionValue.model_presentation;
      },
    );

    this.updateTargetInColumn(
      this.startingAtPriceTargets,
      columnNumber,
      (startingAtPriceTarget) => {
        startingAtPriceTarget.innerHTML =
          highlightsForOptionValue.starting_at_price;
      },
    );

    this.updateTargetInColumn(
      this.inStockIconTargets,
      columnNumber,
      (inStockIconTarget) => {
        if (highlightsForOptionValue.preorder) {
          inStockIconTarget.classList.add('hidden');
        } else {
          inStockIconTarget.classList.remove('hidden');
        }
      },
    );

    this.updateTargetInColumn(
      this.preorderIconTargets,
      columnNumber,
      (preorderIconTarget) => {
        if (highlightsForOptionValue.preorder) {
          preorderIconTarget.classList.remove('hidden');
        } else {
          preorderIconTarget.classList.add('hidden');
        }
      },
    );

    this.updateTargetInColumn(
      this.shippingTimeTargets,
      columnNumber,
      (shippingTimeTarget) => {
        shippingTimeTarget.innerHTML = highlightsForOptionValue.preorder
          ? highlightsForOptionValue.batch.display_name
          : this.inStockShippingTimeCopyValue;
      },
    );
  }

  updateTargetInColumn(targets, columnNumber, callback) {
    targets.forEach((target) => {
      if (target.dataset.columnNumber === columnNumber) {
        callback(target);
      }
    });
  }
}
