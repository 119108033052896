import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['slideContainer', 'scrollHandle'];

  static values = {
    stickySlider: Boolean,
    scrollPastFamilyComponent: Boolean,
  };

  connect() {
    if (!this.hasScrollHandleTarget || !this.scrollPastFamilyComponentValue) {
      return;
    }

    if (!this.isLargeDesktop() && this.stickySliderValue) {
      setTimeout(() => {
        const { height } = this.slideContainerTarget.getBoundingClientRect();
        this.scrollHandleTarget.style.scrollMarginTop = `${height}px`;
        this.scrollHandleTarget.scrollIntoView();
      }, 500);
    } else {
      this.scrollHandleTarget.scrollIntoView();
    }
  }
}
