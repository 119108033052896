import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['container', 'navigationItem'];

  initialize() {
    this.scrollingInterval = null;

    this.swiper = new Swiper(this.containerTarget, {
      spaceBetween: 32,
      init: false,
      slidesPerView: 'auto',
      modules: [Navigation],
    });

    this.observer = new IntersectionObserver(
      this.handleIntersection.bind(this),
      {
        root: null,
        rootMargin: '0% 0% -40% 0%',
        threshold: 0.2,
      },
    );
  }

  connect() {
    const anchorSelector = this.getCurrentAnchorSelector();

    this.navigationItemTargets.forEach((target) => {
      const anchor = target.getAttribute('href');
      const contentEl = document.querySelector(anchor);
      this.observer.observe(contentEl);
    });

    this.swiper?.init();

    const currentNavItem = this.getCurrentNavItem();
    if (currentNavItem) {
      this.highlightNavItem(currentNavItem);
      this.scrollToAnchor(anchorSelector);
      this.slideToNavItem(currentNavItem);
    }
  }

  disconnect() {
    this.swiper?.destroy();
    this.swiper = undefined;
    this.observer.disconnect();
  }

  update(event) {
    this.swiper?.update();
    event.preventDefault();
    const anchor = event.currentTarget.getAttribute('href');
    this.scrollToAnchor(anchor);
    this.slideToNavItem(event.currentTarget);
    this.highlightNavItem(event.currentTarget);
  }

  handleIntersection(entries) {
    if (!this.scrolling) {
      const intersectingEntry = entries
        .filter((entry) => entry.isIntersecting)
        .sort((a, b) => a.intersectionRatio < b.intersectionRatio)[0];

      if (intersectingEntry) {
        const navItemEl = this.getNavItemElement(
          intersectingEntry.target.getAttribute('id'),
        );
        this.highlightNavItem(navItemEl);
        this.slideToNavItem(navItemEl);
      }
    }
  }

  handleWindowResize() {
    this.swiper.slideTo(
      this.navigationItemTargets.indexOf(this.getCurrentNavItem()),
      500,
    );
  }

  /**
   * Clear all highlighted navigation elements.
   * @param {Element} element the navigation item to highlight.
   */
  clearNavItems() {
    this.navigationItemTargets.forEach((el) => {
      if (el.classList.contains('selected')) {
        el?.classList.remove('selected');
      }
    });
  }

  /**
   * Clear the current navigation elements and highlight another one.
   * @param {Element} element the navigation item to highlight.
   */
  highlightNavItem(element) {
    this.clearNavItems();
    element?.classList.add('selected');
  }

  /**
   * Horizontally slides to the target navigation item to get it in view
   * @param {*} target the navigation item to slide to
   */
  slideToNavItem(target) {
    this.swiper.slideTo(this.navigationItemTargets.indexOf(target), 500);
  }

  /**
   * Get the element with a navigation anchor closest to the navbar.
   * @returns the DOM element of the closest content.
   */
  getClosestAnchoredContent() {
    return this.navigationItemTargets.findLast((target) => {
      const href = target.getAttribute('href');
      const targetAnchorEl = document.querySelector(href);

      if (!targetAnchorEl) {
        throw new Error(
          `Secondary navigation failed finding element with "href=${href}"`,
        );
      }

      return (
        targetAnchorEl.getBoundingClientRect().top <= this.getContainerBottom()
      );
    });
  }

  /**
   * Get the currently highlighted item in the navigation bar.
   * @returns a DOM element of the current navigation item.
   */
  getCurrentNavItem() {
    return this.navigationItemTargets.find((target) => {
      const anchorSelector = this.getCurrentAnchorSelector();
      return target?.getAttribute('href') === anchorSelector;
    });
  }

  /**
   * Get a navigation item's DOM element by its ID which correlates to the anchor it points to.
   * @param {*} id the HTML ID of the element for which to find its corresponding navigation item. E.g. "foobar" will return the nav item for "#foobar"
   */
  getNavItemElement(id) {
    const navItemElement = this.navigationItemTargets.findLast(
      (navItemEl) => `#${id}` === navItemEl.getAttribute('href'),
    );

    if (!navItemElement) {
      throw new Error(`No corresponding navigation item with ID ${id}.`);
    }

    return navItemElement;
  }

  /**
   * Get the anchor of the currently selected navigation item as an HTML query selector.
   * @returns a query selector as a string.
   */
  getCurrentAnchorSelector() {
    const anchor =
      this.navigationItemTargets
        .find((target) => {
          return target.classList.contains('selected');
        })
        ?.getAttribute('href') || `#${this.getCurrentUrlAnchor()}`;

    return anchor;
  }

  getContainerBottom() {
    return this.containerTarget.getBoundingClientRect().bottom;
  }

  /**
   * Get the offset of an element from the top of the document.
   * @param {Element} el an HTML element to calculate the document offset for.
   * @returns the offset of the element as an integer.
   */
  getDocumentOffset(el) {
    return el.getBoundingClientRect().top + window.scrollY;
  }

  /**
   * Get the closest container that is being used to stick the nav to the top of the page.
   * Will return the compnent's element if 'sticky' was passed to the `html_classes`, otherwise it searches its parent containers for the class.
   * @returns the closest sticky container element.
   */
  getStickyContainer() {
    return this.containerTarget.closest('.sticky');
  }

  /**
   * Scrolls the page to the anchor.
   * @param {string} anchor a query selector string for the anchor (e.g. '#my_anchor')
   */
  async scrollToAnchor(anchor) {
    const anchorEl = document.querySelector(anchor);
    let top = this.getDocumentOffset(anchorEl);

    top -= this.containerTarget.getBoundingClientRect().bottom;

    // Add additional offset if the stickied element is not currently sticky to compensate for margin lost.
    const stickyOffset =
      this.getStickyContainer().getBoundingClientRect().top === 0
        ? 0
        : this.element.getBoundingClientRect().height;

    window.history.pushState('', '', anchor);

    this.scrolling = true;
    try {
      await this.scroll({ top: Math.ceil(top) + stickyOffset });
    } catch (error) {}
    this.scrolling = false;
  }

  /**
   * Scroll the window to a  position on the page.
   * @param {number} position An object with `top` and `left` values.
   * @returns a Promise that is resolved when the window has scrolled to the position.
   */
  scroll({ top, left }) {
    const timeout = 250;
    const ms = 10;
    clearInterval(this.scrollingInterval);

    window.scrollTo({
      top,
      left,
      behavior: 'smooth',
    });

    return new Promise((resolve, reject) => {
      let elapsed = 0;

      this.scrollingInterval = setInterval(() => {
        const { pageYOffset } = window;
        elapsed += ms;

        if (pageYOffset === top) {
          clearInterval(this.scrollingInterval);
          resolve();
        }

        if (elapsed >= timeout) {
          clearInterval(this.scrollingInterval);
          reject();
        }
      }, ms);
    });
  }
}
