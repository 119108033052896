import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = [
    'preselectedChoice',
    'selectedChoice',
    'selectedChoicePlaceholder',
    'reselectChoicePlaceholder',
    'missingChoiceIcon',
    'selectedChoiceIcon',
    'reselectChoiceIcon',
    'batchSummaryContainer',
  ];

  static values = {
    sectionName: String,
    sectionUrlTitle: String,
    sectionUrl: String,
    reselectSectionMessage: String,
    unselectedSectionMessage: String,
    selectedChoicePlaceholderText: String,
  };

  static outlets = [
    'containers--product-configurations--accordion-component--accordion',
  ];

  static classes = ['hiddenChoicePlaceholder', 'hiddenChoice', 'missingChoice'];

  connect() {
    this.onEvent('resetChoice', (event) => this.handleReset(event));
    this.missingSelectionErrorText = this.selectedChoicePlaceholderTextValue;
  }

  containersProductConfigurationsAccordionComponentAccordionOutletConnected() {
    this.setPreselectedChoiceData();
  }

  setPreselectedChoiceData() {
    if (!this.hasPreselectedChoiceTarget) {
      return;
    }
    const { presentationHtml, showBatchSummary } =
      this.preselectedChoiceTarget.dataset;

    const incomplete = false;
    this.setSelectedState(presentationHtml, showBatchSummary, incomplete);
  }

  updateOptionValue({ currentTarget, target }) {
    if (!target.id.match('help')) {
      const {
        presentationHtml,
        showBatchSummary,
        incomplete,
        absoluteDisplayAmount,
      } = currentTarget.dataset;
      const optionValuePresentationHtml = `${presentationHtml}&nbsp;&nbsp;${absoluteDisplayAmount}`;
      this.setSelectedState(
        optionValuePresentationHtml,
        showBatchSummary,
        incomplete,
      );
    }
  }

  // Variant has been selected, can we remember this to check if it will get hidden
  // ??
  updateSelectedChoice({ currentTarget, target }) {
    if (!target.id.match('help')) {
      const { presentationHtml, showBatchSummary, incomplete } =
        currentTarget.dataset;
      this.setSelectedState(presentationHtml, showBatchSummary, incomplete);
      this.currentSelectedChoice = currentTarget;
    }
  }

  setSelectedState(
    selectedChoicePresentationHtml,
    showBatchSummary,
    incomplete,
  ) {
    this.setSelectedChoicePresentation(selectedChoicePresentationHtml);
    this.toggleBatchSummaryText(showBatchSummary);
    this.hideSelectedChoicePlaceholder();
    this.hideSelectionIcons();
    this.toggleReselectChoicePlaceholder(false);
    if (incomplete === 'true') return;

    this.element.dataset.selected = 'true';
    this.accordionOutlet?.setSelected();
    this.fireSelectionMadeEvent();
    this.showSelectionIcon('selected');
  }

  setResetForAll() {
    this.toggleBatchSummaryText(false);
    this.hideSelectionIcons();
    this.setSelectedChoicePresentation('');
  }

  setResetForMissedSelections() {
    this.hideSelectedChoicePlaceholder();
    this.showSelectionIcon('reselect');
    this.toggleReselectChoicePlaceholder(true);
  }

  setSelectedChoicePresentation(presentationHtml = '') {
    this.selectedChoiceTarget.innerHTML = presentationHtml;
    const show = presentationHtml !== '';
    this.selectedChoiceTarget.classList.toggle(this.hiddenChoiceClass, !show);
  }

  showSelectionIcon(selectionType) {
    let icon;
    switch (selectionType) {
      case 'missing':
        icon = this.missingChoiceIconTarget;
        break;
      case 'selected':
        icon = this.selectedChoiceIconTarget;
        break;
      case 'reselect':
        icon = this.reselectChoiceIconTarget;
        break;
      default:
        return;
    }

    this.hideSelectionIcons();
    icon.classList.remove(...this.hiddenChoiceClasses);
  }

  hideSelectionIcons() {
    this.missingChoiceIconTarget.classList.add(...this.hiddenChoiceClasses);
    this.selectedChoiceIconTarget.classList.add(...this.hiddenChoiceClasses);
    this.reselectChoiceIconTarget.classList.add(...this.hiddenChoiceClasses);
  }

  hideSelectedChoicePlaceholder() {
    this.selectedChoicePlaceholderTarget.classList.add(
      this.hiddenChoicePlaceholderClass,
    );
  }

  setSelectedChoicePlaceholderText() {
    this.selectedChoicePlaceholderTarget.innerHTML =
      this.missingSelectionErrorText;
  }

  showSelectedChoicePlaceholder() {
    this.selectedChoicePlaceholderTarget.classList.remove(
      this.hiddenChoicePlaceholderClass,
    );
  }

  toggleBatchSummaryText(show) {
    this.batchSummaryContainerTarget.classList.toggle('hidden', !show);
  }

  toggleReselectChoicePlaceholder(show) {
    this.reselectChoicePlaceholderTarget.classList.toggle(
      this.hiddenChoicePlaceholderClass,
      !show,
    );
  }

  fireSelectionMadeEvent() {
    this.fireEvent('selectionMade', {
      accordionOutlet: this.accordionOutlet,
      choiceOutlet: this,
    });
  }

  setMissingSelectionState() {
    this.setSelectedChoicePlaceholderText(this.missingSelectionErrorText);
    this.showSelectedChoicePlaceholder();
    this.showSelectionIcon('missing');
    this.setSelectedChoicePresentation('');
    this.selectedChoicePlaceholderTarget.classList.add(
      ...this.missingChoiceClasses,
    );
  }

  handleReset(event) {
    const {
      detail: { sectionName, message, resetType = 'missedSelections' },
    } = event;
    if (sectionName !== this.sectionNameValue) {
      return;
    }
    event.preventDefault();

    this.setResetForAll();
    if (resetType === 'missedSelections') {
      this.showSnackbar(
        message || this.reselectSectionMessageValue,
        'alert',
        this.sectionUrlTitleValue,
        this.sectionUrlValue,
      );
      this.setResetForMissedSelections();
      this.accordionOutlet?.open();
    } else if (resetType === 'sectionHidden') {
      this.showSelectedChoicePlaceholder();
    }
  }

  showUnselectedSectionMessage() {
    this.showSnackbar(
      this.unselectedSectionMessageValue,
      'alert',
      this.sectionUrlTitleValue,
      this.sectionUrlValue,
    );
  }

  onValidationTextChange(event) {
    this.unselectedSectionMessageValue =
      event.detail.unselectedSelectionMessage;
    this.missingSelectionErrorText = event.detail.unselectedSelectionMessage;
  }

  onValidationChange(event) {
    this.element.dataset.selected = String(event.detail.isValid);
  }

  get accordionOutlet() {
    // Outlets are assumed to be present when invoked
    // https://stimulus.hotwired.dev/reference/outlets#outlets-are-assumed-to-be-present,
    // but in #connect they are not available https://github.com/hotwired/stimulus/issues/618
    // even though the helper method has<outlet_name>Outlet returns true, so we can't use it
    // and must catch the exception
    try {
      return this
        .containersProductConfigurationsAccordionComponentAccordionOutlet;
    } catch (e) {
      return null;
    }
  }
}
