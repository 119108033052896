import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = [
    'country',
    'einNumber',
    'einNumberContainer',
    'vatNumber',
    'vatNumberContainer',
  ];

  connect() {
    this.update();
  }

  update() {
    if (!this.hasCountryTarget) return;

    const selectedOption = this.selectedOption();
    if (!selectedOption) return;

    const vatIncluded = selectedOption.dataset.vatIncluded === 'true';
    this.einNumberContainerTarget.classList.toggle('hidden', vatIncluded);
    this.einNumberTarget.disabled = vatIncluded;
    this.vatNumberContainerTarget.classList.toggle('hidden', !vatIncluded);
    this.vatNumberTarget.disabled = !vatIncluded;
  }

  selectedOption() {
    return this.countryTarget.options[this.countryTarget.selectedIndex];
  }
}
