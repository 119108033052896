import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['input'];

  disable() {
    this.inputTarget.disabled = true;
  }

  enable() {
    this.inputTarget.disabled = false;
  }
}
