import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['link'];

  static values = { selectedClass: String };

  redirect(e) {
    e.preventDefault();

    this.dispatchCategoryChangedEvent(e.target.href);
    this.highlightCategory(e.target.dataset.category);
  }

  dispatchCategoryChangedEvent(href) {
    const event = new CustomEvent('category-changed', {
      bubbles: true,
      cancelable: true,
      detail: { href },
    });
    this.element.dispatchEvent(event);
  }

  highlightCategory(category) {
    this.linkTargets.forEach((link) => {
      if (link.classList.contains(this.selectedClassValue)) {
        link.classList.remove(this.selectedClassValue);
      }

      if (link.dataset.category === category) {
        link.classList.add(this.selectedClassValue);
      }
    });
  }
}
