import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['header', 'iconContainer'];

  static outlets = [
    'containers--product-configurations--slider-component--slider',
    'containers--product-configurations--header-single-step-component--scroll',
  ];

  selected = false;

  connect() {
    const debounce = (func, delay) => {
      return function debounced(...args) {
        setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    };

    const setTopDebounced = debounce(() => this.setTop(), 400);

    const handleEvent = (event) => {
      if (!this.hasHeaderOutlet) return;

      if (event.type === 'resize') {
        setTopDebounced();
      } else {
        this.setTop();
      }

      this.setScrollMt();
    };

    ['resize', 'headerVisibilityChanged'].forEach((event) => {
      this.onEvent(event, handleEvent);
    });
  }

  setTop() {
    if (!this.hasHeaderOutlet) return;

    const headerHeight = this.headerOutlet.getHeight();
    const sliderOffsetHeight = this.sliderOutlet.getOffsetHeight();

    const top = sliderOffsetHeight + headerHeight - 1;
    this.headerTarget.style.top = this.isLargeDesktop() ? '' : `${top}px`;
  }

  setScrollMt() {
    const headerHeight = this.headerOutlet.getHeight();
    const sliderOffsetHeight = this.sliderOutlet.offsetHeight;

    const scrollMarginTop = sliderOffsetHeight + headerHeight - 1;
    this.headerTarget.style.scrollMarginTop = this.isLargeDesktop()
      ? ''
      : `${scrollMarginTop}px`;
  }

  toggle(e) {
    if (e.target.closest('a')?.dataset?.preventAccordionToggle === 'true') {
      return;
    }

    this.headerTarget.classList.toggle('opened');
  }

  open() {
    this.headerTarget.classList.add('opened');
  }

  close() {
    this.headerTarget.classList.remove('opened');
  }

  setSelected(selected = true) {
    this.selected = selected;
  }

  dimAccordion() {
    this.iconContainerTarget.classList.add('accordion--dim-text');
  }

  undimAccordion() {
    this.iconContainerTarget.classList.remove('accordion--dim-text');
  }

  get sliderOutlet() {
    return this.containersProductConfigurationsSliderComponentSliderOutlet;
  }

  get headerOutlet() {
    return this
      .containersProductConfigurationsHeaderSingleStepComponentScrollOutlet;
  }

  get hasHeaderOutlet() {
    return this
      .hasContainersProductConfigurationsHeaderSingleStepComponentScrollOutlet;
  }
}
