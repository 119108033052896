import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static outlets = ['containers--header-component--header'];

  get headerOutletElement() {
    return this.containersHeaderComponentHeaderOutletElement;
  }

  stickySidebar() {
    this.element.style.top = `${this.headerOutletElement.offsetHeight}px`;

    if (this.isMobile()) {
      if (window.scrollY > this.headerOutletElement.offsetHeight) {
        this.fixToTop();
      } else {
        this.notFixed();
      }
    } else if (window.scrollY > this.headerOutletElement.offsetHeight) {
      this.fixToTop();
    } else {
      this.notFixed();
    }
  }

  fixToTop() {
    this.element.classList.add('sticky');
  }

  notFixed() {
    this.element.classList.remove('sticky');
  }
}
