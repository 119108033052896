import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['checkSvg', 'checkboxInput'];

  connect() {
    this.render();
  }

  clicked() {
    this.toggleCheckbox();
    this.render();
  }

  toggleCheckbox() {
    this.checkboxInputTarget.checked = !this.checkboxInputTarget.checked;
    this.checkboxInputTarget.click();
  }

  render() {
    if (this.checkboxInputTarget.checked) {
      this.checkSvgTarget.classList.remove('hidden');
      this.element.classList.remove(...this.uncheckedStyles);
      this.element.classList.add(...this.checkedStyles);
    } else {
      this.checkSvgTarget.classList.add('hidden');
      this.element.classList.remove(...this.checkedStyles);
      this.element.classList.add(...this.uncheckedStyles);
    }
  }

  get checkedStyles() {
    return [
      'bg-secondary-alt',
      'hover:bg-secondary-alt-hover',
      'border-secondary/30',
      'pl-1',
      'pr-3',
      'lg:pl-2.5',
      'lg:pr-4',
    ];
  }

  get uncheckedStyles() {
    return [
      'bg-surface',
      'border-utility-outline',
      'hover:bg-[#E1E1E0]',
      'px-5',
      'lg:px-7',
    ];
  }
}
