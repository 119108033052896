import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['container', 'select', 'iconImage'];

  static classes = ['selectedBorder', 'unselectedBorder'];

  static values = {
    variantId: Number,
    maximumAllowed: Number,
    sectionName: String,
    rootElementDisabledStyles: String,
    iconImageDisabledStyles: String,
  };

  static outlets = [
    'pages--product-configuration-component--product-configuration',
    'containers--product-configurations--section-component--multiple-choice',
    'elements--profiles--order--edit-product-configuration-form-component--edit-product-configuration-form',
  ];

  selectTargetConnected(target) {
    target.addEventListener('change', () => {
      if (this.hasEditProductConfigurationFormOutlet) {
        this.editProductConfigurationFormOutlet.selectChanged();
      }
    });
  }

  selectTargetDisconnected(target) {
    target.removeEventListener('change', target.onChange);
  }

  updateBorder() {
    if (!this.hasSelect) return;

    if (this.quantity > 0) {
      this.markSelected();
    } else {
      this.markUnselected();
    }
    this.fireEvent('selection-changed');
  }

  markSelected() {
    this.containerTarget.classList.add(...this.selectedBorderClasses);
    this.containerTarget.classList.remove(...this.unselectedBorderClasses);
  }

  markUnselected() {
    this.containerTarget.classList.add(...this.unselectedBorderClasses);
    this.containerTarget.classList.remove(...this.selectedBorderClasses);
  }

  setQuantity(quantity) {
    if (!this.hasSelect) return;

    this.selectTarget.value = Math.min(quantity, this.maximumAllowedValue);
    this.productConfigurationOutlet.updateTotals();
    this.multipleChoiceSectionOutlet.updateSelectionState();

    this.updateBorder();
  }

  get quantity() {
    if (this.hasSelectTarget) {
      return parseInt(this.selectTarget.value, 10);
    }
    return 0;
  }

  get hasSelect() {
    return this.hasSelectTarget;
  }

  get variantId() {
    return this.variantIdValue;
  }

  disableSelect() {
    // If there is no select element, the variant is unavailable and should be excluded
    if (!this.hasSelectTarget) return;

    this.selectTarget.disabled = true;

    if (this.hasIconImageTarget) {
      const iconImageDisabledStyles =
        this.iconImageDisabledStylesValue.split(' ');
      this.iconImageTarget.classList.add(...iconImageDisabledStyles);
    }

    const rootElementDisabledStyles =
      this.rootElementDisabledStylesValue.split(' ');
    this.element.classList.add(...rootElementDisabledStyles);
  }

  enableSelect() {
    // If there is no select element, the variant is unavailable and should be excluded
    if (!this.hasSelectTarget) return;

    this.selectTarget.disabled = false;

    if (this.hasIconImageTarget) {
      const iconImageDisabledStyles =
        this.iconImageDisabledStylesValue.split(' ');
      this.iconImageTarget.classList.remove(...iconImageDisabledStyles);
    }

    const rootElementDisabledStyles =
      this.rootElementDisabledStylesValue.split(' ');
    this.element.classList.remove(...rootElementDisabledStyles);
  }

  get productConfigurationOutlet() {
    return this.pagesProductConfigurationComponentProductConfigurationOutlet;
  }

  get multipleChoiceSectionOutlet() {
    return this
      .containersProductConfigurationsSectionComponentMultipleChoiceOutlet;
  }

  get editProductConfigurationFormOutlet() {
    return this
      .elementsProfilesOrderEditProductConfigurationFormComponentEditProductConfigurationFormOutlet;
  }

  get hasEditProductConfigurationFormOutlet() {
    return this
      .hasElementsProfilesOrderEditProductConfigurationFormComponentEditProductConfigurationFormOutlet;
  }
}
