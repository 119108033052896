import Choices from 'choices.js';
import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['country', 'state', 'statesContainer'];

  static values = { shipping: Boolean, selectPlaceholder: String };

  connect() {
    this.countrySelector = this.initializeChoices(this.countryTarget);
    this.stateSelector = this.initializeChoices(this.stateTarget);
  }

  async updateStates() {
    const countryId = this.countryTarget.value;
    const states = await this.fetchStates(countryId);

    this.stateSelector.setChoices(states, 'id', 'name', true);
    this.setChoicesVisibility(states.length > 0);
    this.stateSelector.refresh();
  }

  async fetchStates(countryId) {
    if (!countryId) return [];

    let url = `/api/states?country_id=${countryId}`;
    if (this.shippingValue === true) {
      url += '&q[shippable_eq]=true';
    }
    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.states_required) {
          return data.states;
        } else {
          return [];
        }
      });
  }

  setChoicesVisibility(visible) {
    this.stateTarget.required = visible;
    this.statesContainerTarget.hidden = !visible;
  }

  initializeChoices(element) {
    return new Choices(element, {
      classNames: {
        flippedState: 'flipped-none',
      },
      itemSelectText: '',
      shouldSort: false,
      allowHTML: true,
      placeholder: true,
      placeholderValue: this.selectPlaceholderValue,
    });
  }

  enableChoices() {
    this.countrySelector.enable();
    this.stateSelector.enable();
  }

  disableChoices() {
    this.countrySelector.disable();
    this.stateSelector.disable();
  }

  disconnect() {
    if (this.countrySelector) this.countrySelector.destroy();
    if (this.stateSelector) this.stateSelector.destroy();
  }
}
