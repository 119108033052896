import Analytics from '../../../assets/javascript/application/analytics';
import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static values = { event: String, formName: String };

  connect() {
    Analytics.track(this.eventValue, { form_name: this.formNameValue });
  }
}
