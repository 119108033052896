import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['paymentMethod'];

  selectPaymentMethod(event) {
    this.hidePaymentMethods();

    this.paymentMethodTargets.forEach((target) => {
      if (target.dataset.methodId === event.target.value) {
        target.classList.remove('hidden');
      }
    });
  }

  hidePaymentMethods() {
    this.paymentMethodTargets.forEach((target) => {
      target.classList.add('hidden');
    });
  }

  showDefaultPaymentMethod() {
    this.paymentMethodTargets.forEach((target) => {
      if (target.dataset.default === 'true') {
        target.classList.remove('hidden');
      }
    });
  }

  connect() {
    this.hidePaymentMethods();
    this.showDefaultPaymentMethod();
  }
}
