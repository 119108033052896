import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['expansionCardsError'];

  updateError() {
    let totalQuantity = 0;

    this.getJsTargets('expansion_card').forEach((quantity) => {
      totalQuantity += parseInt(quantity.value, 10);
    });

    this.expansionCardsErrorTarget.getElementsByClassName(
      'select-exp-cards-quantity',
    )[0].innerHTML = totalQuantity;
  }

  setErrorState() {
    this.expansionCardsErrorTarget.classList.remove('bg-tertiary-light');
    this.expansionCardsErrorTarget.classList.add(
      'bg-error-10',
      'border',
      'border-error-30',
    );
  }
}
