import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['input', 'content'];

  toggle(event) {
    if (this.inputTarget.checked) {
      event.preventDefault();
      this.inputTarget.checked = false;
    }
  }
}
