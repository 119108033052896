import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['companyField', 'vatNumberField'];

  static values = { currentVatNumber: String, currentCompany: String };

  initialize() {
    this.showOrHideVatIdField();
  }

  handleCompanyFieldChange(event) {
    const companyValue = event.target.value;
    this.showOrHideVatIdField(companyValue);
  }

  showOrHideVatIdField(companyValue) {
    if (companyValue === undefined || companyValue === '') {
      companyValue = this.currentCompanyValue;
    }

    if (companyValue === '' || companyValue === undefined) {
      this.vatNumberFieldTarget.classList.add('hidden');
    } else {
      this.vatNumberFieldTarget.classList.remove('hidden');
    }
  }
}
