import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  stickyCircle() {
    if (window.scrollY < 600) {
      this.hideCircle();
    } else {
      this.showCircle();
    }

    this.element.style.transform = `rotate(${window.pageYOffset}deg)`;
  }

  showCircle() {
    this.element.classList.add('js-pinned');
  }

  hideCircle() {
    this.element.classList.remove('js-pinned');
  }
}
