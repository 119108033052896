import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static swiperModules = [Navigation, Pagination];

  static targets = ['container', 'buttonNext', 'buttonPrev', 'pagination'];

  initialize() {
    this.swiper = new Swiper(this.containerTarget, {
      init: false,
      centeredSlides: true,
      direction: 'horizontal',
      loop: true,
      modules: this.constructor.swiperModules,
      navigation: {
        nextEl: this.buttonNextTarget,
        prevEl: this.buttonPrevTarget,
      },
      pagination: {
        clickable: true,
        el: this.paginationTarget,
      },
      slidesPerView: 1,
      preloadImages: false,
      eventsPrefix: '',
    });
  }

  connect() {
    this.swiper?.init();
  }

  update() {
    this.swiper?.update();
  }

  disconnect() {
    super.disconnect();
    this.swiper?.destroy();
    this.swiper = undefined;
  }
}
