import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['other'];

  selectionChanged(event) {
    const showOther = event.target.value === 'other';
    this.otherTarget.required = showOther;
    this.otherTarget.classList.toggle('invisible', !showOther);
  }
}
