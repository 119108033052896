import Meta from './meta';

const User = {
  anonymousId() {
    return Meta.findByName('anonymous-id');
  },

  userId() {
    return Meta.findByName('user-id');
  },

  id() {
    const userId = this.userId();
    if (userId) {
      return userId;
    }

    return this.anonymousId();
  },
};

export default User;
