import Player from '@vimeo/player';
import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = [
    'video',
    'button',
    'pauseIcon',
    'playIcon',
    'replayIcon',
    'spinner',
  ];

  static classes = ['hidden'];

  static values = {
    autoplay: Boolean,
    controls: Boolean,
    loop: Boolean,
    lazyLoading: Boolean,
  };

  connect() {
    this.iframe = this.videoTarget.querySelector('iframe');
    if (!this.iframe) return;

    this.intersectionObserverAvailable = 'IntersectionObserver' in window;
    if (this.lazyLoadingValue && this.intersectionObserverAvailable) {
      this.createIframeObserver().observe(this.iframe);
    } else {
      this.loadIframe();
    }
  }

  createIframeObserver() {
    return new IntersectionObserver((entries, self) => {
      if (entries[0].isIntersecting) {
        self.disconnect(this.iframe);
        this.loadIframe();
      }
    });
  }

  loadIframe() {
    if (!this.iframe.src) this.iframe.src = this.iframe.dataset.src;
    this.iframe.onload = (e) => this.configurePlayer(e);
  }

  configurePlayer(event) {
    this.player = new Player(event.target);
    this.player.on('play', () => this.showIcon(this.pauseIconTarget));
    this.player.on('pause', () => this.showIcon(this.playIconTarget));
    this.player.on('ended', () => this.showIcon(this.replayIconTarget));

    this.player.ready().then(() => this.hideSpinner());

    if (this.loopValue) {
      this.player.setLoop(true);
    }

    if (this.autoplayValue) {
      this.showIcon(this.pauseIconTarget);
      this.player.setMuted(true).then(() => this.play());
    }

    if (this.controlsValue) {
      this.showButton();
    } else if (this.intersectionObserverAvailable) {
      this.playerObserver = this.createPlayerObserver();
      this.playerObserver.observe(this.videoTarget);
    }
  }

  createPlayerObserver() {
    return new IntersectionObserver((entries) => {
      this.player.getEnded().then((ended) => {
        if (ended) {
          return;
        }

        if (entries[0].isIntersecting) {
          this.play();
        } else {
          this.pause();
        }
      });
    });
  }

  action() {
    Promise.all([this.player.getEnded(), this.player.getPaused()]).then(
      (values) => {
        if (values.find(Boolean)) {
          this.play();
        } else {
          this.pause();
        }
      },
    );
  }

  play() {
    this.playPromise = this.player.play().catch(() => this.play());
  }

  pause() {
    if (this.playPromise) {
      this.playPromise.then(this.player.pause());
    } else {
      this.player.pause();
    }
  }

  showButton() {
    this.buttonTarget.classList.remove(this.hiddenClass);
  }

  showIcon(icon) {
    this.hideIcons();
    icon.classList.remove(this.hiddenClass);
  }

  hideIcons() {
    this.playIconTarget.classList.add(this.hiddenClass);
    this.pauseIconTarget.classList.add(this.hiddenClass);
    this.replayIconTarget.classList.add(this.hiddenClass);
  }

  hideSpinner() {
    this.spinnerTarget.classList.add(this.hiddenClass);
  }

  disconnect() {
    if (this.playerObserver) {
      this.playerObserver.disconnect();
    }
    if (this.player) {
      this.player.destroy();
    }
  }
}
