const Meta = {
  findByName(name) {
    if (!name) {
      return undefined;
    }

    const meta = document.querySelector(`meta[name="${name}"]`);
    if (!meta) {
      return undefined;
    }

    return meta.content;
  },
};

export default Meta;
