import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['specPreviewTitle', 'desktopSpecDetail'];

  mobileSelectedSpecClass = 'mobile-selected';

  desktopSelectedSpecClass = 'desktop-selected';

  connect() {
    this.selectForDesktop(0);
  }

  select({ currentTarget }) {
    const selectedIndex = this.getTargetIndex(currentTarget);

    this.selectForMobile(selectedIndex);
    this.selectForDesktop(selectedIndex);
  }

  selectForMobile(selectedIndex) {
    this.specPreviewTitleTargets.forEach((target, index) => {
      if (selectedIndex === index) {
        target.classList.toggle(this.mobileSelectedSpecClass);
      } else {
        target.classList.remove(this.mobileSelectedSpecClass);
      }
    });
  }

  selectForDesktop(selectedIndex) {
    this.specPreviewTitleTargets.forEach((target, index) => {
      target.classList.toggle(
        this.desktopSelectedSpecClass,
        index === selectedIndex,
      );
    });

    this.desktopSpecDetailTargets.forEach((target, index) =>
      target.classList.toggle('hidden', index !== selectedIndex),
    );
  }

  getTargetIndex(target) {
    return parseInt(target.dataset.indexValue, 10);
  }
}
