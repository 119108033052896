import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['container'];

  initialize() {
    this.swiper = new Swiper(this.containerTarget, {
      init: false,
      modules: [Navigation, Pagination],
      slidesPerView: 'auto',
      spaceBetween: 16,
      preloadImages: false,
    });
  }

  connect() {
    this.swiper?.init();
  }

  disconnect() {
    this.swiper?.destroy();
    this.swiper = undefined;
  }

  update() {
    this.swiper?.update();
  }

  prev() {
    this.swiper.slidePrev();
  }

  next() {
    this.swiper.slideNext();
  }
}
