import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['announcement'];

  static values = {
    announcementId: String,
  };

  connect() {
    try {
      if (
        localStorage.getItem(`announcement-${this.announcementIdValue}`) ===
        'hidden'
      ) {
        this.announcementTarget.style.display = 'none';
      }
    } catch (ignore) {}
  }

  closeAnnouncement() {
    this.announcementTarget.style.display = 'none';
    try {
      localStorage.setItem(
        `announcement-${this.announcementIdValue}`,
        'hidden',
      );
    } catch (ignore) {}
  }
}
