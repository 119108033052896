import ApplicationController from '../../../../application_component/application_controller';
import Loader from '../../../../../assets/javascript/application/loader';

export default class extends ApplicationController {
  static values = { config: Object };

  initialize() {
    Loader.loadScript('https://js.hsforms.net/forms/embed/v2.js', () =>
      this.createForm(),
    );
  }

  createForm() {
    if (!window.hbspt) return;

    window.hbspt.forms.create(this.configValue);
  }
}
