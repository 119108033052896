import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['pastBatchesContainer', 'toggleIcon', 'batchToggleText'];

  static values = {
    initialHiddenState: Boolean,
    showBatchesText: String,
    hideBatchesText: String,
  };

  connect() {
    this.batchesHidden = this.initialHiddenStateValue;
  }

  toggleBatchTimeline(e) {
    e.preventDefault();

    this.batchesHidden = !this.batchesHidden;

    this.togglePastBatchesContainer();
    this.toggleDropdownText();
  }

  /**
   * Animates the opening and closing of the past batches by using max-height and opacity CSS.
   */
  togglePastBatchesContainer() {
    this.pastBatchesContainerTarget.classList.toggle(
      'max-h-[160rem]',
      !this.batchesHidden,
    );

    ['max-h-0', 'opacity-0'].forEach((classname) =>
      this.pastBatchesContainerTarget.classList.toggle(
        classname,
        this.batchesHidden,
      ),
    );
  }

  toggleDropdownText() {
    this.batchToggleTextTarget.innerText = this.batchesHidden
      ? this.showBatchesTextValue
      : this.hideBatchesTextValue;

    this.toggleIconTarget.classList.toggle('rotate-180', !this.batchesHidden);
  }
}
