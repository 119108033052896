import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static values = { shippingEqBilling: Boolean };

  static targets = ['billingAddress'];

  static outlets = ['elements--forms--address-component--country'];

  useShippingAddress(event) {
    this.updateBillAddress(event.currentTarget.checked);
  }

  updateBillAddress(shippingEqBilling) {
    if (shippingEqBilling) {
      this.billingAddressTarget.classList.toggle('hidden', true);
      this.billingAddressTarget.disabled = true;
      this.billingAddressOutlet?.disableChoices();
    } else {
      this.billingAddressTarget.classList.toggle('hidden', false);
      this.billingAddressTarget.disabled = false;
      this.billingAddressOutlet?.enableChoices();
    }
  }

  get billingAddressOutlet() {
    return this.countryOutlets.find((outlet) => !outlet.shippingValue);
  }

  get countryOutlets() {
    return this.elementsFormsAddressComponentCountryOutlets;
  }
}
