import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['input', 'outOfStock', 'image', 'description'];

  static values = {
    available: Boolean,
    inStock: Boolean,
    hasPrice: Boolean,
    imageDisabledClass: String,
    descriptionDisabledClass: String,
    sectionName: String,
  };

  connect() {
    this.registerForPreorderStateUpdates();
  }

  registerForPreorderStateUpdates() {
    this.onEvent('variantSelected', (event) => {
      const { preorder } = event.detail;

      this.variantIsPreorder = preorder;
      this.updateAvailability();
    });
  }

  updateAvailability() {
    if (!this.hasPriceValue) return;

    if (!this.inStockValue) {
      this.updateOutOfStockElementVisibility();
      this.updateImageState();
      this.updateDescriptionState();
      this.updateInputState();
    }
  }

  updateInputState() {
    if (this.variantIsPreorder) {
      this.enableInput();
    } else {
      if (this.inputTarget.checked) this.fireResetChoiceEvent();
      this.disableInput();
    }
  }

  disableInput() {
    this.inputTarget.checked = false;
    this.inputTarget.disabled = true;
  }

  enableInput() {
    this.inputTarget.disabled = false;
  }

  updateOutOfStockElementVisibility() {
    this.outOfStockTarget.classList.toggle('hidden', this.variantIsPreorder);
  }

  updateImageState() {
    this.imageTargets.forEach((imageTarget) =>
      imageTarget.element.classList.toggle(
        this.imageDisabledClassValue,
        !this.variantIsPreorder,
      ),
    );
  }

  updateDescriptionState() {
    this.descriptionTarget.classList.toggle(
      this.descriptionDisabledClassValue,
      !this.variantIsPreorder,
    );
  }

  fireResetChoiceEvent() {
    this.fireEvent('resetChoice', {
      sectionName: this.sectionNameValue,
      resetType: 'missedSelections',
    });
  }
}
