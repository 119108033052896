import ApplicationController from '../../application_component/application_controller';

export default class extends ApplicationController {
  static outlets = [
    'containers--products--sticky-product-navigation-component--tabs',
  ];

  scrollToOverview() {
    const targetElement = document.querySelector('.tabs-content');
    const overviewTabIndex = this.tabsOutlet.getTabIndex('overview');

    targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

    this.tabsOutlet.tabTargets[overviewTabIndex]?.click();
  }

  get tabsOutlet() {
    return this.containersProductsStickyProductNavigationComponentTabsOutlet;
  }
}
