import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['optionInput'];

  static outlets = [
    'containers--product-categories--filters--selection-component--filter',
  ];

  get filterOutletElement() {
    return this
      .containersProductCategoriesFiltersSelectionComponentFilterOutletElement;
  }

  toggleFilter() {
    const filters = this.optionInputTargets
      .filter((target) => target.checked)
      .map((filter) => filter.value);

    this.dispatchFiltersChangedEvent(filters);
  }

  dispatchFiltersChangedEvent(filters) {
    const event = new CustomEvent('filters-changed', {
      bubbles: true,
      cancelable: true,
      detail: { filterName: 'tags[]', filters },
    });
    this.filterOutletElement.dispatchEvent(event);
  }
}
