import { loadStripe } from '@stripe/stripe-js/pure';
import ApplicationController from '../../../../../application_component/application_controller';
import Meta from '../../../../../../assets/javascript/application/meta';
import StripeError from './stripe_error';

const tailwindConfig = preval`
      const resolveConfig = require('tailwindcss/resolveConfig');
      const tailwindConfig = require('../../../../../../../tailwind.config');
      module.exports = resolveConfig(tailwindConfig);
    `;

export default class extends ApplicationController {
  static targets = ['element', 'errors', 'submitButton'];

  static values = {
    publishableKey: String,
    intentEndpoint: String,
    processPaymentEndpoint: String,
    billingDetails: Object,
    redirectUrl: String,
    cartUrl: String,
    orderNumber: String,
    errorMessage: String,
    advancedFraudDetection: Boolean,
  };

  appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: tailwindConfig.theme.colors.accent['brand-orange'],
      fontFamily: 'graphik, Arial, sans-serif',
    },
    rules: {
      '.Label': {
        color: '#FFFFFF',
      },
      '.Tab--selected': {
        border: '2px solid #F45A27',
      },
    },
  };

  initialize() {
    this.language = Meta.findByName('language');
    this.authToken = Meta.findByName('csrf-token');
    this.publishableKey = this.publishableKeyValue;
    this.intentEndpoint = this.intentEndpointValue;
    this.processPaymentEndpoint = this.processPaymentEndpointValue;
    this.redirectURL = this.redirectUrlValue;
    this.cartURL = this.cartUrlValue;
    this.billingDetails = this.billingDetailsValue;
    this.orderNumber = this.orderNumberValue;
    this.errorMessage = this.errorMessageValue;
  }

  async connect() {
    try {
      this.hideSubmitButton();
      this.intent = await this.retrivePaymentIntent();

      if (this.intent.status === 'succeeded') {
        this.redirectToSuccess();
      } else {
        loadStripe.setLoadParameters({
          advancedFraudSignals: this.advancedFraudDetectionValue,
        });
        this.stripe = await loadStripe(this.publishableKey);

        this.setupStripe();
      }
    } catch (error) {
      this.showError(this.errorMessage);
    }
  }

  async retrivePaymentIntent() {
    const url = `${this.intentEndpoint}?authenticity_token=${this.authToken}&order_number=${this.orderNumber}`;

    const result = await this.request(url, 'GET');

    if (result.error) {
      throw new StripeError(result.error);
    }

    return result;
  }

  redirectToSuccess() {
    const url = new URL(this.redirectURL);
    url.searchParams.set('redirect_status', 'succeeded');
    window.location.replace(url);
  }

  redirectToCart() {
    const url = new URL(this.cartURL);
    window.location.replace(url);
  }

  setupStripe() {
    this.elements = this.stripe.elements({
      locale: this.language,
      clientSecret: this.intent.clientSecret,
      appearance: this.appearance,
    });

    this.paymentElement = this.elements.create('payment', {
      fields: {
        billingDetails: 'never',
      },
    });
    this.paymentElement.mount(this.elementTarget);
    this.element.style.display = 'inherit';

    this.addInputChangeListener();
    this.addSubmitButtonListener();

    this.paymentElement.on('ready', () => this.showSubmitButton());
  }

  addInputChangeListener() {
    this.paymentElement.on('change', (event) => {
      if (event.complete === true) {
        this.enableSubmitButton();
      } else {
        this.disableSubmitButton();
      }
    });
  }

  async addSubmitButtonListener() {
    this.submitButtonTarget.addEventListener('click', async (event) => {
      try {
        event.preventDefault();

        this.fireEvent('payment-started');

        const { success } = await this.processPayment();

        if (success === false) {
          this.redirectToCart();

          return;
        }

        const { elements } = this;
        const { error } = await this.stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: this.redirectURL,
            payment_method_data: {
              billing_details: this.billingDetails,
            },
          },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === 'card_error' || error.type === 'validation_error') {
          this.showError(error.message);
        } else {
          this.showError(this.errorMessage);
        }
      } catch (error) {
        this.showError(this.errorMessage);
      }
    });
  }

  showError(error) {
    const message = error.message || error;

    this.errorsTarget.textContent = message;

    this.fireEvent('payment-finished');
  }

  showSubmitButton() {
    this.submitButtonTarget.classList.remove('hidden');
  }

  hideSubmitButton() {
    this.submitButtonTarget.classList.add('hidden');
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false;
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true;
  }

  async processPayment() {
    const result = await this.request(this.processPaymentEndpoint, 'PATCH', {
      authenticity_token: this.authToken,
      order_number: this.orderNumber,
    });

    if (result.error) {
      throw new StripeError(result.error);
    }

    return result;
  }

  async request(url, method, body = null) {
    const options = {
      method,
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(url, options);

    if (!response.ok) {
      throw new StripeError('Request Error');
    }

    const result = await response.json().catch(() => {
      throw new StripeError('Parse error');
    });

    return result;
  }
}
