import ApplicationController from '../../application_component/application_controller';
import Variant from './variant';
import OptionValuesAvailability from './option_values_availability';

export default class extends ApplicationController {
  static values = {
    currentOptionValuesMap: Object,
    variantsMap: Array,
    reselectOptionValuesRequiredMessage: String,
  };

  static targets = [
    'singleSelectionOptionInput',
    'multipleSelectionOptionInput',
    'inStockElement',
    'preorderElement',
  ];

  static outlets = [
    'containers--product-configurations--option-value-component--option-value',
    'containers--product-configurations--batch-component--batch',
    'containers--product-configurations--totals-component--totals',
  ];

  connect() {
    this.variants = this.getVariants();
    this.currentOptionValuesMap = { ...this.currentOptionValuesMapValue };

    this.updateOptionValues();
    this.updateTotals();
    this.updatePreorderMode();
    this.updateBatchInfo();
  }

  selectOptionValue(event) {
    const { optionTypeId, optionValueId } = event.params;
    this.currentOptionValuesMap[optionTypeId] = optionValueId;

    this.resetUnavailableOptionValues();
    this.updateOptionValues();
    this.updateTotals();
    this.updatePreorderMode();
    this.updateBatchInfo();

    this.fireEvent('variantSelected', {
      preorder: this.currentVariant.preorder,
    });
  }

  updatePreorderMode() {
    if (this.currentVariant?.preorder) {
      this.showPreorderElements();
    } else {
      this.showInStockElements();
    }
  }

  showPreorderElements() {
    this.inStockElementTargets.forEach((element) => {
      element.classList.add('hidden');
      const input = element.querySelector('input');
      if (input) input.checked = false;
    });
    this.preorderElementTargets.forEach((element) =>
      element.classList.remove('hidden'),
    );
  }

  showInStockElements() {
    this.inStockElementTargets.forEach((element) => {
      element.classList.remove('hidden');
      const input = element.querySelector('input');
      if (input) input.checked = false;
    });
    this.preorderElementTargets.forEach((element) =>
      element.classList.add('hidden'),
    );
  }

  updateTotals() {
    if (!this.currentVariant) return;

    const totalAmount =
      this.currentVariant.amount + this.getAddonsTotalPriceAmount();

    this.totalsOutlets.forEach((totalsOutlet) => {
      totalsOutlet.updateTotal(totalAmount);
    });
  }

  getAddonsTotalPriceAmount() {
    let totalAmount = 0;
    this.singleSelectionOptionInputTargets.forEach((input) => {
      if (!input.checked) return;
      totalAmount += parseFloat(input.dataset.price);
    });
    this.multipleSelectionOptionInputTargets.forEach((input) => {
      totalAmount += parseFloat(input.dataset.price) * input.value;
    });
    return totalAmount;
  }

  resetUnavailableOptionValues() {
    if (this.currentVariant?.isAvailable) return;

    let firstOOSoptionValueOutlet;
    const incrementalOptionValuesMap = {};

    this.optionValuesOutlets.forEach((outlet) => {
      const optionTypeId = outlet.optionTypeIdValue;

      incrementalOptionValuesMap[optionTypeId] =
        this.currentOptionValuesMap[optionTypeId];
      const optionValuesAvailability = new OptionValuesAvailability(
        this.variants,
        incrementalOptionValuesMap,
      );
      if (optionValuesAvailability.available || outlet.isProcessorValue) return;

      outlet.reset();
      delete this.currentOptionValuesMap[optionTypeId];

      if (!firstOOSoptionValueOutlet) {
        firstOOSoptionValueOutlet = outlet;
      }
    });

    if (firstOOSoptionValueOutlet) {
      this.fireEvent('resetChoice', {
        sectionName: firstOOSoptionValueOutlet.sectionNameValue,
        message: this.reselectOptionValuesRequiredMessageValue,
      });
    }
  }

  updateOptionValues() {
    if (Object.keys(this.currentOptionValuesMap).length === 0) return;

    this.optionValuesOutlets.forEach((outlet) => {
      const optionValuesAvailability = new OptionValuesAvailability(
        this.variants,
        this.getOutletOptionValuesMap(outlet),
      );
      outlet.updateAvailability(optionValuesAvailability);

      const relatedVariant = this.getRelatedVariant(outlet);
      const cheapestVariant = this.getCheapestVariant(outlet);
      outlet.updatePrice(this.currentVariant, relatedVariant, cheapestVariant);
    });
  }

  updateBatchInfo() {
    if (!this.hasBatchOutlets || !this.currentVariant) return;

    this.batchOutlets.forEach((batchOutlet) => {
      batchOutlet.updateBatchInfoLabels(
        this.currentVariant.batchPresentation,
        this.currentVariant.batchShipLabel,
      );
    });
  }

  getVariants() {
    return this.variantsMapValue
      .map((record) => new Variant(record))
      .sort((variant1, variant2) => {
        if (variant1.isAvailable && !variant2.isAvailable) {
          return -1;
        }

        if (!variant1.isAvailable && variant2.isAvailable) {
          return 1;
        }

        // If availability is the same, sort by price
        return variant1.amount - variant2.amount;
      });
  }

  getVariant(optionValuesMap) {
    return this.variants.find((variant) =>
      variant.hasOptionValuesMap(optionValuesMap),
    );
  }

  getOutletOptionValuesMap(outlet) {
    if (this.optionTypeIds.length <= 2) {
      return this.getRelatedOptionValuesMap(outlet);
    } else {
      return this.getPartialOptionValuesMap(outlet);
    }
  }

  getRelatedOptionValuesMap(outlet) {
    return {
      ...this.currentOptionValuesMap,
      [outlet.optionTypeIdValue]: outlet.optionValueId,
    };
  }

  getPartialOptionValuesMap(outlet) {
    const relatedOptionValuesMap = this.getRelatedOptionValuesMap(outlet);
    const partialOptionValuesMap = {};
    for (const key in relatedOptionValuesMap) {
      partialOptionValuesMap[key] = relatedOptionValuesMap[key];
      if (key === outlet.optionTypeIdValue.toString())
        return partialOptionValuesMap;
    }
  }

  getRelatedVariant(outlet) {
    return this.getVariant(this.getRelatedOptionValuesMap(outlet));
  }

  getCheapestVariant(outlet) {
    if (!this.currentVariant) return null;

    return this.variants.find((variant) => {
      return variant.hasOptionValuesMap(
        this.currentVariant.optionValuesMapExcept(outlet.optionTypeIdValue),
      );
    });
  }

  get currentVariant() {
    return this.getVariant(this.currentOptionValuesMap);
  }

  get optionValuesOutlets() {
    return this
      .containersProductConfigurationsOptionValueComponentOptionValueOutlets;
  }

  get totalsOutlets() {
    return this.containersProductConfigurationsTotalsComponentTotalsOutlets;
  }

  get batchOutlets() {
    return this.containersProductConfigurationsBatchComponentBatchOutlets;
  }

  get hasBatchOutlets() {
    return this.hasContainersProductConfigurationsBatchComponentBatchOutlet;
  }

  get optionTypeIds() {
    return [
      ...new Set(
        this.optionValuesOutlets.map((outlet) => outlet.optionTypeIdValue),
      ),
    ];
  }
}
