import ApplicationController from '../../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = [
    'minPrice',
    'maxPrice',
    'thumbBar',
    'minPriceInput',
    'maxPriceInput',
  ];

  static values = {
    min: Number,
    max: Number,
    priceGap: Number,
  };

  initialize() {
    this.dispatchFiltersChangedEvent = this.debounce((filters) => {
      const event = new CustomEvent('filters-changed', {
        bubbles: true,
        cancelable: true,
        detail: filters,
      });
      this.element.dispatchEvent(event);
    });
  }

  connect() {
    this.min = this.minValue;
    this.max = this.maxValue;
    this.priceGap = this.priceGapValue;

    this.updateSlider();
  }

  changeMinPriceInput() {
    if (this.minPriceInputValue() >= this.min) {
      this.minPriceTarget.value = this.minPriceInputTarget.value;
      this.changeMinPrice();
    }
  }

  changeMaxPriceInput() {
    if (this.maxPriceInputValue() > this.minPriceValue() + this.priceGap) {
      this.maxPriceTarget.value = this.maxPriceInputTarget.value;
      this.changeMaxPrice();
    }
  }

  changeMinPrice() {
    this.minPriceTarget.value = `${this.minPrice()}`;
    this.minPriceInputTarget.value = this.minPrice();

    this.updateSlider();

    this.dispatchFiltersChangedEvent({
      filterName: 'min_price',
      filters: [this.minPrice()],
    });
  }

  changeMaxPrice() {
    this.maxPriceTarget.value = `${this.maxPrice()}`;
    this.maxPriceInputTarget.value = this.maxPrice();

    this.updateSlider();

    this.dispatchFiltersChangedEvent({
      filterName: 'max_price',
      filters: [this.maxPrice()],
    });
  }

  updateSlider() {
    const minThumb = this.minThumb();
    const maxThumb = this.maxThumb();

    this.thumbBarTarget.style.left = `${minThumb}%`;
    this.thumbBarTarget.style.right = `${maxThumb}%`;
  }

  minThumb() {
    return ((this.minPrice() - this.min) / (this.max - this.min)) * 100;
  }

  maxThumb() {
    return 100 - ((this.maxPrice() - this.min) / (this.max - this.min)) * 100;
  }

  minPrice() {
    return Math.min(this.minPriceValue(), this.maxPriceValue() - this.priceGap);
  }

  maxPrice() {
    return Math.max(this.maxPriceValue(), this.minPriceValue() + this.priceGap);
  }

  minPriceValue() {
    return parseInt(this.minPriceTarget.value, 10);
  }

  minPriceInputValue() {
    return parseInt(this.minPriceInputTarget.value ?? 0, 10);
  }

  maxPriceValue() {
    return parseInt(this.maxPriceTarget.value, 10);
  }

  maxPriceInputValue() {
    return parseInt(this.maxPriceInputTarget.value ?? 0, 10);
  }
}
