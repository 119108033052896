import ApplicationController from '../../../application_component/application_controller';

export default class extends ApplicationController {
  static targets = ['multipleSelectionSelect', 'outOfStock'];

  static values = {
    inStock: Boolean,
    hasPrice: Boolean,
  };

  static disabled_classes = [
    'bg-utility-disabled-background',
    'cursor-not-allowed',
    'border-utility-outline',
    'text-utility-disabled-content',
  ];

  connect() {
    this.registerForPreorderStateUpdates();
  }

  registerForPreorderStateUpdates() {
    this.onEvent('variantSelected', (event) => {
      const { preorder } = event.detail;

      this.variantIsPreorder = preorder;
      this.updateAvailability();
    });
  }

  updateAvailability() {
    if (!this.hasPriceValue) return;

    if (!this.inStockValue) {
      this.multipleSelectionSelectTarget.classList.toggle(
        'hidden',
        !this.variantIsPreorder,
      );
      this.outOfStockTarget.classList.toggle('hidden', this.variantIsPreorder);
      this.constructor.disabled_classes.forEach((className) => {
        this.element.classList.toggle(className, !this.variantIsPreorder);
      });
    }
  }
}
